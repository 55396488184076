import React from "react";
import Styles from "./styles.module.css";

import useTopSlider from "../hooks/useTopSlide";
import ScrollToTop from "../hooks/scrolltotop";
import ListedJournal from "./listedjournal";

function Journal() {
  ScrollToTop();
  const {
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
   
  } = useTopSlider(2000);

  

  return (
    <div className={Styles.container}>
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src="/aboutus/strategicplan.jpg" alt={"AGREC"} />
      )}
      <div className={Styles.subcontainer}>
        <h1>Journal</h1>
        <h3>About the Journal</h3>
        <p>
          AGREC takes pride in fostering intellectual discourse through this
          dedicated journal we publishes peer-reviewed research papers,
          articles, and journals.
        </p>
       <ListedJournal/>
       
      </div>
    </div>
  );
}

export default Journal;
