// GalleryDetail.js
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import galleryData from './gallerydata';
import './styles.css';

export const GalleryDetail = () => {
  const { id } = useParams();
  const galleryItem = galleryData.find((item) => item.id === parseInt(id));
  const [currentImageIndex, setCurrentImageIndex] = useState(1);

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? galleryItem.images.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === galleryItem.images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleZoomIn = () => {
    // Implement zoom-in functionality
    // You can use a modal or any other technique for zooming in
  };

  return (
    <div className="gallery-detail-container">
      <h2>{galleryItem.title}</h2>
      <div className="gallery-slides">
        <img
          src={galleryItem.imageUrl}
          alt={galleryItem.title}
          onClick={handleZoomIn}
        />
        <button onClick={handlePrevious}>Previous</button>
        <button onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default GalleryDetail;
