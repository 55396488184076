import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
// import Typography from "@mui/material/Typography";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";

import {
  PublicFinanceData,
  DevolutionData,
  DemocraticGovernanceData,
  DataProtectionData,
  PolicyMakingData,
  EthicalData,
} from "./themesdata";

export default function ConferenceThemes() {
  const [openTheme, setOpenTheme] = useState({
    div1: false,
    div2: false,
    div3: false,
    div4: false,
    div5: false,
    div6: false,
  });

  const handleHover = (divKey) => {
    setOpenTheme((prevDivOpen) => ({
      ...prevDivOpen,
      [divKey]: !prevDivOpen[divKey],
    }));
  };

  return (
    <div>
      {PublicFinanceData.map((theme, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <div
              style={{ display: "flex", flexDirection: "row" }}
              onClick={() => handleHover("div1")}
            >
              {!openTheme.div1 ? <FaPlusCircle /> : <FaMinusCircle />}
              <h4 style={{ margin: "-0.2rem", padding: "0rem 2rem" }}>
                {theme.title}
              </h4>{" "}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {theme.points.map((point, index2) => (
              <ul key={index2}>
                <li>{point.point}</li>
              </ul>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
      {DevolutionData.map((theme, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <div
              style={{ display: "flex", flexDirection: "row" }}
              onClick={() => handleHover("div2")}
            >
              {!openTheme.div2 ? <FaPlusCircle /> : <FaMinusCircle />}
              <h4 style={{ margin: "-0.2rem", padding: "0rem 2rem" }}>
                {theme.title}
              </h4>{" "}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {theme.points.map((point, index2) => (
              <ul key={index2}>
                <li>{point.point}</li>
              </ul>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
      {DemocraticGovernanceData.map((theme, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <div
              style={{ display: "flex", flexDirection: "row" }}
              onClick={() => handleHover("div3")}
            >
              {!openTheme.div3 ? <FaPlusCircle /> : <FaMinusCircle />}
              <h4 style={{ margin: "-0.2rem", padding: "0rem 2rem" }}>
                {theme.title}
              </h4>{" "}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {theme.points.map((point, index2) => (
              <ul key={index2}>
                <li>{point.point}</li>
              </ul>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
      {DataProtectionData.map((theme, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <div
              style={{ display: "flex", flexDirection: "row" }}
              onClick={() => handleHover("div4")}
            >
              {!openTheme.div4 ? <FaPlusCircle /> : <FaMinusCircle />}
              <h4 style={{ margin: "-0.2rem", padding: "0rem 2rem" }}>
                {theme.title}
              </h4>{" "}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {theme.points.map((point, index2) => (
              <ul key={index2}>
                <li>{point.point}</li>
              </ul>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
      {PolicyMakingData.map((theme, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <div
              style={{ display: "flex", flexDirection: "row" }}
              onClick={() => handleHover("div5")}
            >
              {!openTheme.div5 ? <FaPlusCircle /> : <FaMinusCircle />}
              <h4 style={{ margin: "-0.2rem", padding: "0rem 2rem" }}>
                {theme.title}
              </h4>{" "}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {theme.points.map((point, index2) => (
              <ul key={index2}>
                <li>{point.point}</li>
              </ul>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
      {EthicalData.map((theme, index) => (
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <div
              style={{ display: "flex", flexDirection: "row" }}
              onClick={() => handleHover("div6")}
            >
              {!openTheme.div6 ? <FaPlusCircle /> : <FaMinusCircle />}
              <h4 style={{ margin: "-0.2rem", padding: "0rem 2rem" }}>
                {theme.title}
              </h4>{" "}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {theme.points.map((point, index2) => (
              <ul key={index2}>
                <li>{point.point}</li>
              </ul>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
