import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  PublicFinanceData,
  DevolutionData,
  DemocraticGovernanceData,
  DataProtectionData,
  PolicyMakingData,
  EthicalData,
} from "../conference/themesdata";
import Styles from "../styles.module.css";
import ScrollToTop from "../../hooks/scrolltotop";

export default function SubTheme() {
  const navigate = useNavigate();
  ScrollToTop();
  const [theme, setTheme] = useState([]);
  const { postId } = useParams();
  useEffect(() => {
    chooseArray(postId, setTheme);
  }, [postId]);

  return (
    <div className={Styles.container2}>
      {theme.map((theme, index) => (
        <div key={index} className={Styles.content}>
          <Link to="/conference registration">
            <img src="/images/conference.jpg" alt="AGREC" />
          </Link>
          <h1>
            {theme.title.slice(0,3)}
            {":"}
            {theme.title.substring(3)}
          </h1>

          <button className={Styles.button} onClick={() => navigate(-1)}>
            Back to conference
          </button>

          <>
            <table id={Styles.table} className={Styles.table}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Theme</th>
                </tr>
              </thead>
              <tbody>
                {theme.points.map((subtheme, indexsubtheme) => (
                  <tr key={indexsubtheme}>
                    <td style={{fontWeight:"bold", color:"var(--primaryColor)"}}>{subtheme.id}</td>
                    <td>{subtheme.point}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <ol key={indexsubtheme}>
                <li>
                  <p style={{ fontWeight: "bold" }}>{subtheme.id}</p>&emsp;
                  <p>{subtheme.point}</p>
                </li>
              </ol> */}
          </>
        </div>
      ))}
    </div>
  );
}

function chooseArray(param, setTheme) {
  // Choose data file based on postId from params
  switch (param) {
    case "1":
      setTheme(PublicFinanceData);
      break;
    case "2":
      setTheme(DevolutionData);
      break;
    case "3":
      setTheme(DemocraticGovernanceData);
      break;
    case "4":
      setTheme(DataProtectionData);
      break;
    case "5":
      setTheme(PolicyMakingData);
      break;
    case "6":
      setTheme(EthicalData);
      break;
    default:
      // Handle invalid param
      setTheme(PublicFinanceData);
      console.error("Invalid Kenya:", param);
  }
  return param;
}
