import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Styles from "./styles1.module.css";
import { Journals } from "./journalData";

function ListedJournal() {
  const [journal, setJournal] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      try {
        setJournal(Journals);
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className={Styles.themesContainer}>
      {journal.map((journal, index) => (
        <div className={Styles.productCard} key={index}>
          <div className={Styles.favoriteContainer}>
            <div className={Styles.favouriteImage}>
            <Link to={`/${journal.link}`} target="_blank">
                <img src={journal.image} alt="AGREC" />{" "}
              </Link>
            </div>
          </div>
          <div className={Styles.productInfo}>
            <h5>{journal.title}</h5>
            <p>{journal.authors}</p>
          </div>
          <div className={Styles.addcart}>
            <div className={Styles.addcartSection}>
              <Link to={`/${journal.link}`} target="_blank">
                <button className={Styles.button}>Read More</button>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ListedJournal;
