import React from "react";

function TermsConditions() {
  return (
    <div>
      <h1>Terms and Conditions</h1>
    </div>
  );
}

export default TermsConditions;
