import React from "react";
import { Link } from "react-router-dom";
import MainMenuLinks from "./mainmenulinks";

function ColumnB() {
  return (
    <div>
      <h3>Quick Links</h3>
      <ul>
        {MainMenuLinks.map((menu) => (
          <div key={menu.id}>
            <li>
              <Link to={menu.link}>{menu.menu}</Link>
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
}

export default ColumnB;
