import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ShowCaseCard from "./showcasecard";
import Styles from "./styles.module.css";

export default function ShowCase() {
  return (
    <div className={Styles.grid}>
      <h1>Our Areas of Specilization</h1>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {Data.map((item, index) => (
            <Grid item xs={4} sm={4} md={4} key={index}>
              <ShowCaseCard
                src={item.src}
                alt={item.alt}
                title={item.title}
                description={item.description}
                link={item.link}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}

const Data = [
  {
    title: "Civil Society Organisations",
    src: "images/cardcivilsociety.jpg",
    alt: "AGREC-Civil Society",
    description:
      "Vibrant Civil Society Organisation and Citizens, is the pillar of effective and efficient democratic Governance and flourishing local development. We work with Civil Society organisations and citizens to resolve the challenges of perennial challenges of poor planning and lack of community engagement.",
    link: "./programs/civil society organisations",
  },

  {
    title:
      "Institutionalizing County and National Governments and Private Sector",
    src: "images/cardgovernment.jpg",
    alt: "AGREC-Government & Private Sector",
    description:
      "A stronger institution, with capacity manpower, inclusive legal and policy documents and enhanced collaboration between partners is key to robust economic growth..",
    link: "./programs/government & private sector",
  },
  {
    title: "Democracy, Political Parties, Political Parties Liason",
    src: "images/cardpoliticalparties.jpg",
    alt: "AGREC-Political Parties",
    description:
      "Collaborates with election institutions, IEBC and ORPP, to build the capacity of political parties and Political parties Liaison committees, civil society organizations and local and international election observers to ensure a democratic society.",
    link: "./programs/civil society organisations",
  },
  {
    title: "Research",
    src: "images/cardresearch.jpg",
    alt: "AGREC-Research",
    description:
      "In the realm of surveys, assessments, and research, AGREC excels, our proficiency in data collection is exemplified through the use of cutting-edge tools such as ODK (Open Data Kit), KOBO, and CS (CommCare). ",
    link: "./programs/civil society organisations",
  },
  {
    title: "Special Services/Training",
    src: "images/trainings.jpg",
    alt: "AGREC-Special Services/Training",
    description:
      "Development and design of informational educational materials (IEC Materials), including translation of documents to braille, and Kenya sign language, and supply of assistive devices.",
    link: "./programs/speacial trainings & services",
  },
  {
    title: "Journal",
    src: "images/journal.jpg",
    alt: "AGREC-Journal",
    description:
      "AGREC takes pride in fostering intellectual discourse through this dedicated journal we publishes peer-reviewed research papers, articles, and journals. This journal serves as a beacon for researchers, academia and practitioners.",
    link: "./journal/journal",
  },
];
