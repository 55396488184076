import styles from "./styles.module.css";
import ColumnA from "./columnA";
import ColumnB from "./columnB";
import ColumnC from "./columnC";
import ColumnD from "./columnD";

const Footer = () => {
  return (
    <>
      <div className={styles.grid}>
        <div className={styles.card}>
          <ColumnA />
        </div>

        <div className={styles.card}>
          <ColumnB />
        </div>
        <div className={styles.card}>
          <ColumnC />
        </div>
      </div>
      <div>
        <ColumnD />
      </div>
    </>
  );
};

export default Footer;
