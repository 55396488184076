import React from "react";
import Styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { AttentionSeeker, Zoom } from "react-awesome-reveal";

export default function WhereWeWork() {
  return (
    <div className={Styles.ourmission}>
      <div className={Styles.ourmissioncolumns}>
        <div>
          <img src="/images/wherewework.png" alt="AGREC-Vision Mission" />
        </div>
      </div>
      <div className={Styles.ourmissioncolumns}>
        <AttentionSeeker effect="tada">
          <h1>{data.title}</h1>
        </AttentionSeeker>
        <Zoom>
          <p>{data.description}</p>
        </Zoom>
        <Link to="/media/Conference Detail">
          <button className={Styles.button}>Read More</button>
        </Link>
      </div>
    </div>
  );
}

const data = {
  title: "Where we Work",
  description:
    "AGREC operates in the Eastern African Region, with its primary operations in Kenya , South Sudan, Somalia, and Ethiopia, collaborating with diverse stakeholders in Civil Society, Governments, Private Sectors, Political Parties, and NGOs. Our consultancy services span the African continent, addressing regional challenges faced by our Institutions and communities.",
};
