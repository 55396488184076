import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Styles from "./styles.module.css";
import DietaryRestrictions from "./dietaryestrictions";
import Accomodation from "./accommodation";
import TravelInformation from "./travelinformation";
import Disability from "./disability";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import AgreeToTermsSlider from "../agreetotermsslider";

export default function DateAccomodation({ prev, submit }) {
  const eventStartDate = new Date("2024-09-04");
  const weekBeforeEvent = new Date(eventStartDate);
  weekBeforeEvent.setDate(eventStartDate.getDate() - 7);
  const defaultDate = new Date("2024-09-06");

  const formik = useFormik({
    initialValues: {
      diet: "",
      travelInfo: "",
      assistanceRequired: "",
      assistanceType: "",
      interestedInAccommodation: "",
      bookingDate: defaultDate,
      checkoutDate: defaultDate,
      agreeToTerms: "",
    },
    validationSchema: Yup.object({
      interestedInAccommodation: Yup.string().required("Required"),
      bookingDate: Yup.date().when("interestedInAccommodation", {
        is: "Yes",
        then: (schema) =>
          schema
            .required("Required")
            .min(
              weekBeforeEvent,
              `Booking date should not be earlier than ${
                weekBeforeEvent.toISOString().split("T")[0]
              }`
            )
            .max(
              eventStartDate,
              `Booking date should not be later than ${
                eventStartDate.toISOString().split("T")[0]
              }`
            ),
        otherwise: (schema) => schema.required().default(defaultDate),
      }),
      checkoutDate: Yup.date().when("interestedInAccommodation", {
        is: "Yes",
        then: (schema) =>
          schema
            .required("Required")
            .min(
              Yup.ref("bookingDate"),
              "Checkout date cannot be before booking date"
            )
            .max(
              new Date(
                eventStartDate.getFullYear(),
                eventStartDate.getMonth(),
                eventStartDate.getDate() + 7
              ),
              "Checkout date cannot be more than 7 days after the conference"
            ),
        otherwise: (schema) => schema.required().default(defaultDate),
      }),
    }),
    onSubmit: (values) => {
      if (values.interestedInAccommodation === "No") {
        values.bookingDate = defaultDate;
        values.checkoutDate = defaultDate;
      }
      submit(values);
    },
  });

  return (
    <div className={Styles.container}>
      <form onSubmit={formik.handleSubmit}>
        <DietaryRestrictions
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          handleDiet={formik.values.diet}
          handleTouched={formik.touched.diet}
          handleErrors={formik.errors.diet}
        />
        <TravelInformation
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          handleTravel={formik.values.travelInfo}
          handleTouched={formik.touched.travelInfo}
          handleErrors={formik.errors.travelInfo}
        />
        <Disability
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          valueassistanceType={formik.values.assistanceType}
          touchassistanceType={formik.touched.assistanceType}
          errorsassistanceType={formik.errors.assistanceType}
          touchassistanceRequired={formik.touched.assistanceRequired}
          errorassistanceRequired={formik.errors.assistanceRequired}
        />
        <div>
          <Accomodation
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            handleinterestedInAccommodation={
              formik.values.interestedInAccommodation
            }
            handleTouchedinterestedInAccommodation={
              formik.touched.interestedInAccommodation
            }
            handleErrorsinterestedInAccommodation={
              formik.errors.interestedInAccommodation
            }
            handleBookingDate={formik.values.bookingDate}
            handleTouchedBookingDate={formik.touched.bookingDate}
            handleErrorsBookingDate={formik.errors.bookingDate}
            handleCheckOutDate={formik.values.checkoutDate}
            handleTouchedCheckOutDate={formik.touched.checkoutDate}
            handleErrorsCheckOutDate={formik.errors.checkoutDate}
          />
        </div>
        <div className={Styles.allbuttons}>
          <AgreeToTermsSlider />
          <button type="submit">
            Submit <KeyboardDoubleArrowRight />
          </button>
          <button type="button" onClick={prev}>
            <KeyboardDoubleArrowLeft /> <span>Previous</span>
          </button>
        </div>
      </form>
    </div>
  );
}
