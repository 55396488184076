import React from "react";
import Styles from "./styles.module.css";

function Payment() {
  return (
    <div className={Styles.themesContainer}>
      <h3 style={{ textAlign: "center", padding: "2rem" }}>
        For Booking & Reservations use the payment options {">>"}:
      </h3>
      {data.map((item, index) => (
        <div className={Styles.productCard} key={index}>
          <div className={Styles.productInfo}>
            <img src={item.image} alt="AGREC" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default Payment;

const data = [
  { image: "/images/bankdetails.png" },
  { image: "/images/mpesadetails.png" },
];
