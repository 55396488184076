import React from "react";
import Styles from "./styles.module.css";
import useTopSlider from "../hooks/useTopSlide";

function ResearchCommunication() {
  
  const {
    featuredImage = "/images/slider5.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);


  return (
    <div className={Styles.container}>
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
      <h1>Research and Communication</h1>
      <h3>Surveys, Assessments, and Research</h3>
      <p>
        AGREC stands at the forefront of providing comprehensive and dynamic
        research and data services, offering a suite of solutions that encompass
        surveys, assessments, research initiatives, data collection, bulk data
        collection, and entry services.
      </p>

      <p>
        Our commitment to excellence in these domains positions us as a trusted
        partner for clients across diverse sectors, including Civil Society
        organizations, Governments, Private Sectors, Political Parties, and
        Non-Governmental Organizations (NGOs).
      </p>

      <p>
        In the realm of surveys, assessments, and research, AGREC excels in
        designing and executing studies that delve into various aspects of
        community development, governance, and project impact. Leveraging our
        extensive experience and a team of skilled professionals, we tailor our
        approach to address the unique challenges and objectives of each
        project.
      </p>
      <h3>AGREC's Intervention Solutions</h3>
      <p>
        Our proficiency in data collection is exemplified through the use of
        cutting-edge tools such as ODK (Open Data Kit), KOBO, and CS (CommCare).
        This enables us to conduct efficient and accurate bulk data collection
        and entry, ensuring the integrity and reliability of the information
        gathered. The utilization of these tools also streamlines the process,
        allowing for real-time data validation and quality control.
      </p>

      <p>
        AGREC's strength extends to robust data analysis capabilities, where we
        transform raw data into meaningful insights. Our analysts employ
        advanced techniques to discern patterns, trends, and correlations,
        providing clients with a nuanced understanding of the information at
        hand. The culmination of our services lies in the production of
        comprehensive and visually compelling reports, where we distill complex
        data into actionable recommendations.
      </p>
      <h3>Our Approach</h3>
      <p>
        In essence, AGREC's holistic approach to surveys, assessments, research,
        and data services reflects our unwavering commitment to delivering
        impactful and evidence-based solutions, empowering our clients to make
        informed decisions and drive positive change.
      </p>
    </div>
  );
}

export default ResearchCommunication;
