import React from "react";

function Feedback() {
  return (
    <div>
      <h1>Feedback</h1>
    </div>
  );
}

export default Feedback;
