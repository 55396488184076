import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Styles from "./styles.module.css";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";

export default function ConferencePreferences({ next, prev }) {
  const formik = useFormik({
    initialValues: {
      topics: [],
    },
    validationSchema: Yup.object({
      topics: Yup.array()
        .min(1, "At least one topic must be selected")
        .required("Required"),
    }),
    onSubmit: (values) => {
      next(values);
    },
  });

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    const currentTopics = formik.values.topics;
    const newTopics = currentTopics.includes(value)
      ? currentTopics.filter((topic) => topic !== value)
      : [...currentTopics, value];

    formik.setFieldValue("topics", newTopics);
  };

  return (
    <div className={Styles.highlight}>
      <div>
        <h3>
          ----------------Select at least one conference theme area of
          interest----------------
        </h3>
        <hr />
        <form onSubmit={formik.handleSubmit}>
          <label>
            <input
              type="checkbox"
              name="topics"
              value="Public Finance Management, Taxation and Public Debt Management"
              checked={formik.values.topics.includes(
                "Public Finance Management, Taxation and Public Debt Management"
              )}
              onChange={handleCheckboxChange}
            />
            Public Finance Management, Taxation and Public Debt Management
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              name="topics"
              value="Devolution, Intergovernmental Relations and Service Delivery"
              checked={formik.values.topics.includes(
                "Devolution, Intergovernmental Relations and Service Delivery"
              )}
              onChange={handleCheckboxChange}
            />
            Devolution, Intergovernmental Relations and Service Delivery
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              name="topics"
              value="Best Practice in Democratic Governance"
              checked={formik.values.topics.includes(
                "Best Practice in Democratic Governance"
              )}
              onChange={handleCheckboxChange}
            />
            Best Practice in Democratic Governance
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              name="topics"
              value="Emerging issues and best practice in Data protection, digital governance and access to information"
              checked={formik.values.topics.includes(
                "Emerging issues and best practice in Data protection, digital governance and access to information"
              )}
              onChange={handleCheckboxChange}
            />
            Emerging issues and best practice in Data protection, digital
            governance and access to information
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              name="topics"
              value="Policy Making, Execution and Service Delivery"
              checked={formik.values.topics.includes(
                "Policy Making, Execution and Service Delivery"
              )}
              onChange={handleCheckboxChange}
            />
            Policy Making, Execution and Service Delivery
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              name="topics"
              value="Ethical Accountable Governance in Public Service"
              checked={formik.values.topics.includes(
                "Ethical Accountable Governance in Public Service"
              )}
              onChange={handleCheckboxChange}
            />
            Ethical Accountable Governance in Public Service
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              name="topics"
              value="Special Events or Workshops"
              checked={formik.values.topics.includes(
                "Special Events or Workshops"
              )}
              onChange={handleCheckboxChange}
            />
            Special Events or Workshops
          </label>
          <br />
          <label>
            <input
              type="checkbox"
              name="topics"
              value="Networking Interests (e.g., specific industries, job roles)"
              checked={formik.values.topics.includes(
                "Networking Interests (e.g., specific industries, job roles)"
              )}
              onChange={handleCheckboxChange}
            />
            Networking Interests (e.g., specific industries, job roles)
          </label>
          <br />

          <div className={Styles.errors}>
            {formik.touched.topics && formik.errors.topics ? (
              <div>{formik.errors.topics}</div>
            ) : null}
          </div>
          <button type="submit">
            Next <KeyboardDoubleArrowRight />
          </button>
          <div>
            <button type="button" onClick={prev}>
              <KeyboardDoubleArrowLeft /> <span>Previous</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
