import React from "react";
import { Link, Outlet } from "react-router-dom";
import Styles from "./styles.module.css";

import Footer from "../footer";
import NavBar from "../navbar/navbar";
import MainMenuLinks from "../footer/mainmenulinks";
import ConferenceDateContact from "./conferencedatecontact";


function Layout2() {
  
  return (
    <div>
      <NavBar />
      <div className={Styles.container}>
        <div className={Styles.column1}>
          <h4>Quick Links</h4>
          {MainMenuLinks.map((menulink, index) => (
            <li key={index}>
              <Link to={menulink.link}>{menulink.menu}</Link>{" "}
            </li>
          ))}{" "}
          <div className={Styles.column5}>
            <ConferenceDateContact />
          </div>
        </div>
        <div className={Styles.column2}>
          <Outlet />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Layout2;
