import React from "react";
import Styles from "./styles.module.css";
import CivilSocietyTable1 from "./tables/civilsocietytable1";
import useTopSlider from "../hooks/useTopSlide";
import ScrollToTop from "../hooks/scrolltotop";


function CivilSocietyOrganisations() {
  ScrollToTop()
  const {
    featuredImage = "/images/slider2.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);
  
  return (
    
    <div className={Styles.container}>
     
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
      <h1>Civil Society Organisations</h1>
      <h3>Devolution</h3>
      <p>
        Devolution of power and resources is a pivotal means of promoting and
        advancing democracy, participation and accountability. Based on the
        objectives of Devolution as captured in{" "}
        <span>Article 174 of the constitution</span>, the promises of Devolution
        can be conceptualized as bringing with it opportunities towards
        equality, participation, accountability and effective service delivery.
        Devolution is also enhancing good governance by incorporating vertical
        separation of powers accountability and increasing the ambit of checks
        and balances.
      </p>

      <p>
        Vibrant Civil Society Organisation and Citizens, is the pillar of
        effective and efficient democratic Governance and flourishing local
        development. CSOs include community groups, non-governmental
        organisations, youth associations, advocacy groups, think tanks, labour
        unions, charitable organizations, faith-based organizations,
        professional associations and foundations Civil society also includes
        formal and informal civic networks, such as social movements. These
        civic and social structures shape public policy, provide important
        information to citizens and governments, hold governments accountable,
        defend basic rights, deliver social services, foster civic engagement
        and empower citizens to solve social challenges.
      </p>

      <p>
        The involvement of civil society organisations is an important strategy
        for ensuring services are designed in a targeted and transparent manner.
        In Kenya, however, a shortage of functional coordination structures
        hampers such participation at the national level, while at the local
        level, weak capacities for financial management, reporting and advocacy
        exclude smaller organisations in particular from planning processes and
        funding opportunities. This jeopardises the sustainability of many
        organisations’ works.
      </p>
      <h3>AGREC's Intervention Solutions</h3>
      <p>
        AGREC brings vast experience in strengthening civil society and
        promoting civic engagement in Kenya and subsidiaries in South Sudan,
        Somalia, and Ethiopia. Our work cultivates strong relationships with
        local partners to design and implement approaches. We have established a
        track record of empowering local actors to strengthen the frameworks,
        laws policies and institutions that form the basis of effective
        governance systems.
      </p>

      <p>
        AGREC Strengthening Civil Society Organizations and Citizens to
        participate at both national and county levels for the benefit of the
        local communities. We work with Civil Society organisations and citizens
        to resolve the challenges of perennial challenges of poor planning and
        lack of community engagement. Additionally, issues of mismatched
        priorities and white elephant projects are rampant. We encourage the
        collaborative approach between government and civil society
        organisations in resolving the issues facing the communities. This is
        done through robust discussion, during budgeting, planning, policy
        discussion and other matters that concern citizens.
      </p>
      <h3>Our Approach</h3>
      <p>
        We believe that well-organised, informed and motivated CSOs and
        citizens, can result in targeted development at national and county
        levels and, when cascaded can be beneficial to the citizens within the
        communities. We do this by increasing citizen knowledge and engagement
        in governance processes, establishing and consolidating networks and
        coalitions for joint impact, Enhancing, county and national government
        accountability to citizens and entrenching governance value in the
        development agenda.{" "}
      </p>

      <p>
        We use two prong approaches in strengthening the civil society
        organisation and citizens, we provide Consultancy and Training. The two
        services are provided at a cost to the client
      </p>
      <div className={Styles.maintable}>
        <CivilSocietyTable1 />
      </div>
    </div>
  );
}

export default CivilSocietyOrganisations;
