import React from "react";
import Styles from "./conferenceregistration.module.css"
import useTopSlider from "../../hooks/useTopSlide"
import RegistrationForm from "./Registration/registrationform";
function ConferenceRegistration() {
  const {
    featuredImage = "/images/slider1.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);
  return (
    <div className={Styles.container}>
        {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
        <h1>
        Inaugural International Multi-Sectoral Conference on Governance and
        Development in Kenya 2024
      </h1>
      <h4>September, 4th-6th, 2024</h4>
      <h1>Registration Form</h1>
     <RegistrationForm/>
    </div>
  );
}

export default ConferenceRegistration;
