import { useMemo } from "react";
import useCurrentPageInfo from "./useCurrentPageInfo";

const useSocialShare = ({ image, description }) => {
  const { title, url } = useCurrentPageInfo();
  const encodedTitle = encodeURIComponent(title);
  const encodedUrl = encodeURIComponent(url);
  const encodedImage = encodeURIComponent(image);
  const encodedDescription = encodeURIComponent(description);

  const urls = useMemo(
    () => ({
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`,
      twitter: `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`,
      whatsapp: `https://api.whatsapp.com/send?text=${encodedTitle} ${encodedUrl}`,
      tiktok: `https://www.tiktok.com/share?url=${encodedUrl}&title=${encodedTitle}`,
      // Instagram does not have a direct share URL
    }),
    [encodedTitle, encodedUrl, encodedImage, encodedDescription]
  );

  return urls;
};

export default useSocialShare;
