import React from "react";
import Styles from "./styles.module.css";
import ScrollToTop from "../components/hooks/scrolltotop"

export default function Nomatch() {
  ScrollToTop()
  return (
    <div className={Styles.container}>
      <h3>Page Not Found</h3>
      <hr />
      <div className={Styles.imageContainer}>
        <img src="aboutus/ourstory.jpg" alt="AGREC" className={Styles.image} />
      </div>
    </div>
  );
}
