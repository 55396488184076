export const TrainingData = [
  { training: "Resource mobilization and fundraising strategies" },
  { training: "Political parties organization and governance " },
  {
    training:
      "Inclusivity and participation of special interest groups in politics ",
  },
  {
    training: "Training strengthening Political Parties League  ",
  },
  { training: "Strategic planning" },
  { training: "Party cooperation and coalition building" },
  { training: "Party campaigns, planning and logistics" },
  { training: "Election agent's training " },
  { training: "Election observation " },
  { training: "Women's participation in Politics" },
];

export const Consultancies = [
  {
    consultancy: "Political party strategic plan",
  },
  {
    consultancy: "Party nomination rules",
  },
  {
    consultancy: "Party manifestos",
  },
  {
    consultancy: "Campaign strategy",
  },
  { consultancy: "Political Parties inclusion strategies " },
  {
    consultancy: "Development of MERLA Framework ",
  },
  {
    consultancy:
      "Development of Institutional Policies, such as HR policies, Communication policies, social media policies, strategic plans, Finance policies, volunteer policies, gender violence policies, Child protection policies, Procurement Policies etc  ",
  },
  { consultancy: "Development of organisation reporting framework" },
  { consultancy: "Development of IEC materials " },
];
