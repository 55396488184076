import React, { useState, useEffect } from "react";
import Styles from "./styles.module.css";
import {  Zoom } from "react-awesome-reveal";

export default function OurWork() {
  const [counts, setCounts] = useState(Data.map((counter) => counter.start));

  useEffect(() => {
    const intervalIds = Data.map((counter, index) =>
      setInterval(() => {
        setCounts((prevCounts) => {
          const updatedCounts = [...prevCounts];
          if (updatedCounts[index] < counter.end) {
            updatedCounts[index] += 1;
          } else {
            clearInterval(intervalIds[index]);
          }
          return updatedCounts;
        });
      }, 100)
    );
    return () => intervalIds.forEach((intervalId) => clearInterval(intervalId));
  }, []);

  return (
    <div className={Styles.ourwork}>
      <div className={Styles.containerA}>
        {counts.map((item, index) => (
          <div className={Styles.columnA} key={index}>
            <Zoom>
              <h1>{item}</h1>
            </Zoom>
          </div>
        ))}
      </div>
      <div className={Styles.containerB}>
        {Data.map((item, index) => (
          <div className={Styles.columnA} key={index}>
            <h3>{item.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

const Data = [
  { start: 0, end: 15, name: "Completed Projects" },
  { start: 0, end: 10, name: "Year of Experience" },
  { start: 0, end: 300, name: "Consultants" },
  { start: 0, end: 60, name: "Researchers" },
  { start: 0, end: 25, name: "Counties" },
  { start: 0, end: 4, name: "Countries" },
];
