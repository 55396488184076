import React from "react";
import Styles from "./styles.module.css";
import useTopSlider from "../hooks/useTopSlide";

function WhereWeWork() {
  const {
    featuredImage = "/aboutus/wherewework.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);
  return (
    <div className={Styles.container}>
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
      <div className={Styles.subcontainer}>
        <h3>Where We work</h3>
        <p>
          AGREC operates in the Eastern African Region, with its primary
          operations in Kenya , South Sudan, Somalia, and Ethiopia,
          collaborating with diverse stakeholders in Civil Society, Governments,
          Private Sectors, Political Parties, and NGOs. Our consultancy services
          span the African continent, addressing regional challenges faced by
          our Institutions and communities.
        </p>
        <p>
          From national issues to remote rural challenges, AGREC's reach extends
          to areas where transformative change is most needed. With a commitment
          to fostering Good Governance, Community Development, and sustainable
          practices, AGREC leaves a resounding impact on regions seeking
          progress and positive transformation.
        </p>
      </div>
    </div>
  );
}

export default WhereWeWork;
