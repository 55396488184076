import React from "react";
import Radio from "@mui/joy/Radio";
import Styles from "./styles.module.css";

export default function Disability({
  handleChange,
  handleBlur,
  valueassistanceType,
  touchassistanceType,
  errorsassistanceType,
  touchassistanceRequired,
  errorassistanceRequired,
  handleassistanceRequired,
}) {
  return (
    <div>
      <h3>Disability Information</h3>
      <div className={Styles.customRadio}>
        <label htmlFor="assistanceRequired">Do you require assistance?</label>

        <input
          id="assistanceRequiredYes"
          name="assistanceRequired"
          type="radio"
          value="Yes"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <label htmlFor="assistanceRequiredYes">Yes</label>
        <input
          id="assistanceRequiredNo"
          name="assistanceRequired"
          type="radio"
          value="No"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <label htmlFor="assistanceRequiredNo">No</label>
        {touchassistanceRequired && errorassistanceRequired ? (
          <div>{errorassistanceRequired}</div>
        ) : null}
      </div>
      <div>
        {" "}
        <label htmlFor="assistanceType">Type of Assistance</label>
        <input
          id="assistanceType"
          name="assistanceType"
          type="text"
          onChange={handleChange}
          onBlur={handleBlur}
          value={valueassistanceType}
        />
        {touchassistanceType && errorsassistanceType ? (
          <div>{errorsassistanceType}</div>
        ) : null}
      </div>
    </div>
  );
}
