const ProfileData = [
  {
    username: "Kennedy Khakaba Omukunda",
    src: "/profiles/Khakaba.jpg",
    alt: "Khakaba Omukunda",
    profile:
      "Team Leader at AGREC, he has more than 10 years experience, conceptualizing, implementing and managing, Governance, Democracy, inclusion, and development program both level of government, Private sector and civil society organizations. Previously, Kennedy has worked for, International Democratic Institute (NDI), USAID AHADI, Sauti Kuu Foundation, AMURT Africa, Hope Worldwide Kenya. He has consulted for Westminster Foundation for Democracy in Kenya. He has conceptualized and implemented program funded by USAID, FCDO, UN, WorldBank, ILO/IPEC, among others. He is an ongoing PhD in Development Studies, he holders Msc in Development Studies(JKUAT), B.A in Sociology and Political Science(UON), Diploma in Project Management(KIM), Diploma Medical Science(TUK) and Certificate in Monitoring and Evaluation. Kennedy is passionate about generating knowledge to empower governance systems not only Africa but the World.",
  },
  {
    username: "Rinah Andayi",
    src: "/profiles/Rinah.jpg",
    alt: "Rinah Andayi",
    profile:
      "Rinah Andayi is an ICT and Knowledge specialist at AGREC with more than 10 years’ experience with bias in the NGO sectors and private sectors. Previously Rinah has build the capacity of NGOs such as Deaf Empowerment Society of Kenya, FAWE, and Becon of Hope-Africa. She's keen on issues around cyber security, end user support, social media management, customer care and training around communication etiquette. She hold Bsc in Information Systems and Technology – Networking. She will be in charge of communication and ICT.",
  },
  {
    username: "Zedekiah Okal",
    src: "/profiles/Okal.jpg",
    alt: "Zedekiah Okal",
    profile:
      "Okal is an NGO Finance and Accounting Specialist, having worked with organisation such as AMREF, Hope Worldwide Kenya among others. He has 10 years combined experience in NGO and private sector. Okal has BBA in accounting among other certification. In the past similar training Okal handled the following areas. He will handle, finance and accounting.",
  },
  {
    username: "Easther N. Nechio",
    src: "/profiles/Easther.jpg",
    alt: "Easther N. Nechio",
    profile:
      "Nechio is a researcher with specialization in climate change and data analysis. Previously, she has worked Government of Kenya Department of Meteorology and JKIA  in Department of Meteorology as Weather Forecaster. She holds Bsc in Meteorology and various certificates in research.",
  },
];

export default ProfileData;
