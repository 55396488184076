import React from "react";
import Styles from "./styles.module.css";
import GovernmentPrivateSectorTable from "./tables/governmentprivatesectortable";
import useTopSlider from "../hooks/useTopSlide";
import ScrollToTop from "../hooks/scrolltotop";

function GovernmentandPrivateSecor() {
  ScrollToTop()
  const {
    featuredImage = "/images/slider3.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);

  return (
    <div className={Styles.container}>
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
      <h1>
        Institutionalizing County and National Governments and Private Sector{" "}
      </h1>
      <h3>Background</h3>
      <p>
        In August 2010 Kenya promulgated a new Constitution; the constitution of
        Kenya 2010. This was a culmination of more than ten years of attempts by
        Kenyans to have a new constitutional dispensation. Kenyans had wanted to
        replace the discredited independence constitution that had been amended
        several times. It had been blamed for the many governance ills that
        Kenya experienced over the years. The ills included economic
        marginalization of some regions and communities, disparities in regional
        economic development and inefficient and poor service delivery. This new
        system of governance took effect in March 2011 with the swearing-in of
        Governors of the 47 county governments. The Constitution of Kenya 2010
        was intended, to among other things, improve service delivery. Indeed,
        one of the major objectives of the devolved system of government was to
        improve service delivery (Article 174 of the Constitution of Kenya
        2010).
      </p>
      <h3>Government Structure</h3>
      <p>
        In Kenya, there are three arms of the{" "}
        <span>
          National Government the Executive, the Legislature, and the Judiciary
        </span>
        . The arms of the{" "}
        <span>
          Devolved Government are County Assemblies and County Executive
          Committees
        </span>
        . Governments provide the legal and social framework, maintain
        competition, provide public goods and services, redistribute income,
        correct for externalities, and stabilize the economy.
      </p>

      <p>
        A thriving African private sector is critical to achieving inclusive
        growth that creates economic opportunities for all. The private sector
        is an inextricable stakeholder in driving and sustaining outcomes
        capable of moving countries beyond the need for assistance. Today, the
        private sector is playing an unprecedented role in creating and shaping
        opportunities that improve the lives of the people and communities
        (USAID)
      </p>
      <h3>AGREC's Approach</h3>
      <p>
        AGREC believes a strong nexus between government and the private sector
        is key to development. A stronger institution, with capacity manpower,
        inclusive legal and policy documents and enhanced collaboration between
        partners is key to robust economic growth. AGREC approaches this
        through, training and consultancy.
      </p>

      <div className={Styles.maintable}>
        <GovernmentPrivateSectorTable />
      </div>
    </div>
  );
}

export default GovernmentandPrivateSecor;
