import React from "react";
import Styles from "../styles.module.css";
import { Consultancies, TrainingData } from "./politicalpartiesliasonData";

function PoliticalPartiesTable() {
  let count = 0;
  let count1 = 0;
  return (
    <div>
      <div style={{ marginBottom: "2rem" }}>
        <table id={Styles.table} className={Styles.table}>
          <thead>
            <tr>
              <th>#</th>
              <th>AGREC Training Programmes</th>
            </tr>
          </thead>
          <tbody>
            {TrainingData.map((item, index) => (
              <tr key={index}>
                <td style={{ width: "5%" }}>
                  <p>{(count += 1)}</p>
                </td>

                <td style={{ width: "95%" }}>
                  <p>{item.training}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        <table id={Styles.table} className={Styles.table}>
          <thead>
            <tr>
              <th>#</th>
              <th>AGREC Counsultancy Services</th>
            </tr>
          </thead>
          <tbody>
            {Consultancies.map((item, index) => (
              <tr key={index}>
                <td style={{ width: "5%" }}>
                  <p>{(count1 += 1)}</p>
                </td>

                <td style={{ width: "95%" }}>
                  <p>{item.consultancy}</p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PoliticalPartiesTable;
