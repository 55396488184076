import React from "react";
import { Link } from "react-router-dom";
import FooterLinks from "./footerlinks";
import styles from "./styles.module.css";

function ColumnD() {
  return (
    <div className={styles.copyright}>
      <div className={styles.containerCopyright}>
        <p> Copyright : &copy; {new Date().getFullYear()} AGREC</p>
      </div>

      <div className={styles.containerCopyright}>
        {FooterLinks.map((menu) => (
          <div key={menu.id}>
            <li>
              <Link to={menu.link}>{menu.menu}</Link>
            </li>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ColumnD;
