// NavBarData.js
const navLinks = [
  { title: "Home", path: "/" },
  {
    title: "About Us",
    path: "/",
    subLinks: [
      "Our Story",
      "Where We Work",
      "Our Values",
      "Our Team",
      "Board Members & Editorial Team",
      "Strategic Plan 2024",
    ],
  },
  {
    title: "Programs",
    path: "/",
    subLinks: [
      "Civil Society Organisations",
      "Government & Private Sector",
      "Democracy & Political Parties",
      "Research & Communication",
      "Speacial Trainings & Services",
    ],
  },
  {
    title: "Journal",
    path: "/#",
    subLinks: ["Journal", "Research Calls", "Methodologies"],
  },
  {
    title: "Media",
    path: "/#",
    subLinks: ["Press Releases", "Conference 2024", "Gallery"],
  },
  {
    title: "Resources",
    path: "/#",
    subLinks: ["Downloads", "Toolkits", "General Reports"],
  },
  { title: "Our Projects", path: "/our projects" },
  { title: "Contact", path: "/contact" },
];

export default navLinks;
