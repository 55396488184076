import React from "react";
import useTopSlider from "../hooks/useTopSlide";
import Styles from "./styles.module.css";
import ScrollToTop from "../hooks/scrolltotop";

export default function ThankYouContact() {
  ScrollToTop();
  const {
    featuredImage = "/images/slider2.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);

  return (
    <div className={Styles.container}>
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}

 
      <h3>Thank You</h3>
      <p>
        Thank you for AGREC Team. We appreciate your interest in our services.{" "}
      </p>
      <p>
        Our team will review your information, and we'll revert back to you
        shortly with a confirmation and any additional details you may need. If
        you have any immediate questions or concerns, feel free to reach out to
        our support team. We look forward to working with you.
      </p>
      <h4>AGREC Team</h4>
    </div>
  );
}
