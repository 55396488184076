import React from "react";
import Styles from "./styles.module.css";

export default function DietaryRestrictions({
  handleChange,
  handleBlur,
  handleDiet,
  handleTouched,
  handleErrors,
}) {
  return (
    <div>
      <div className={Styles.subcontainer2}>
        <div>
          <label htmlFor="diet">*Dietary Restrictions</label>
          <input
            id="diet"
            name="diet"
            type="text"
            placeholder="Specify dietary preferences or restrictions for meals during the conference."
            onChange={handleChange}
            onBlur={handleBlur}
            value={handleDiet}
          />
          <div className={Styles.errors}>
            {handleTouched && handleErrors ? <div>{handleErrors}</div> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
