const SliderData = [
  {
    id: 1,
    image: "/images/conference.jpg",
    alt: "AGREC 1",
  },
  {
    id: 2,
    image: "/images/slider2.jpg",
    alt: "AGREC 2",
  },
  {
    id: 3,
    image: "/images/slider3.jpg",
    alt: "AGREC 3",
  },
  {
    id: 4,
    image: "/images/slider4.jpg",
    alt: "AGREC 3",
  },
  {
    id: 5,
    image: "/images/slider5.jpg",
    alt: "AGREC 3",
  },
  {
    id: 6,
    image: "/images/slider6.jpg",
    alt: "AGREC 3",
  },
];

export default SliderData;
