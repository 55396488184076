import React, { useState } from "react";
import { Box, Typography, Switch, FormControlLabel } from "@mui/material";

const AgreeToTermsToggle = () => {
  const [agreed, setAgreed] = useState(false);

  const handleToggleChange = (event) => {
    setAgreed(event.target.checked);
  };

  return (
    <Box sx={{ width: "100%", margin: "0 auto", textAlign: "center" }}>
      <Typography variant="h6" gutterBottom>
        Consent
      </Typography>
      <FormControlLabel
        label={
          agreed
            ? "You have agreed to the terms of registration."
            : "By submitting this registration form, attendees acknowledge and agree to comply with the terms and conditions of the AGREC Conference. This includes granting permission for the use of their image in conference-related photography or videography and agreeing to adhere to the event's code of conduct. Attendees understand that participation is subject to event policies and guidelines, and they consent to receiving communications related to the conference."
        }
        control={
          <Switch
            checked={agreed}
            onChange={handleToggleChange}
            color="primary"
            name="agreeToTerms"
          />
        }
      />
    </Box>
  );
};

export default AgreeToTermsToggle;
