import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Styles from "./styles.module.css";

export default function ShowCaseCard({ alt, src, title, description, link }) {
  return (
    <div className={Styles.card}>
      <Card sx={{ maxWidth: "100%" }}>
        <CardMedia component="img" alt={alt} height="140" image={src} />
        <CardContent>
          <h3 gutterBottom>{title}</h3>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <CardActions>
          <button className={Styles.button}>
            <Link to={link} style={{ color: "#01ADBE" }}>
              Read More
            </Link>
          </button>
        </CardActions>
      </Card>
    </div>
  );
}
