import React from "react";

function ColumnA() {
  return (
    <div>
      <h3>About AGREC</h3>
      <p>
        <span style={{ color: "var(--lightBlue)", fontWeight: "bold" }}>
          AGREC{" "}
        </span>{" "}
        brings vast experience in strengthening civil society and promoting
        civic engagement in Kenya and subsidiaries in South Sudan, Somalia, and
        Ethiopia. Our work cultivates strong relationships with local partners
        to design and implement approaches.
      </p>
      <p>
        We have established a track record of empowering local actors to
        strengthen the frameworks, laws policies and institutions that form the
        basis of effective governance systems.
      </p>
    </div>
  );
}

export default ColumnA;
