import React from "react";
import Styles from "../styles.module.css";
import {
  participantsData,
  EarlyRegistration,
  LateRegistration,
} from "./conferencedata";

function ConferenceFeesTable() {
  let count = 0;

  return (
    <table id={Styles.table} className={Styles.table}>
      <thead>
        <tr>
          <th>#</th>
          <th>Categories of participants </th>
          <th>Early-bird Registration (31st March to 31st August 2024)</th>
          <th>Late Registration (After 31st August 2024)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={{ width: "4%" }}>
            {participantsData.map((item, index) => (
              <p key={index}>{(count += 1)}</p>
            ))}
          </td>

          <td style={{ width: "36%" }}>
            {participantsData.map((item, index) => (
              <p key={index}>{item.participants}</p>
            ))}
          </td>
          <td style={{ width: "36%" }}>
            {EarlyRegistration.map((item, index) => (
              <p key={index}>{item.participants}</p>
            ))}
          </td>
          <td style={{ width: "36%" }}>
            {LateRegistration.map((item, index) => (
              <p key={index}>{item.participants}</p>
            ))}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ConferenceFeesTable;
