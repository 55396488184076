import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";

export default function OurTeam() {
  return (
    <div className={Styles.ourtemmainContainer}>
      <div className={Styles.ourteamsubcontainerA}>
        <h1>Our Team</h1>
        <p>
          AGREC team consisits of researchers, trainers, and subject matter
          experts to handle all its tasks. AGREC's assignments are conducted
          collaboratively by the team, providing clients with diverse and
          complementary perspectives in all training sessions.
        </p>
        <Link to="./about us/our team">
          <button className={Styles.button}>Read More</button>
        </Link>
      </div>
      <div className={Styles.ourteamsubcontainerB}>
        <div className={Styles.grid}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              spacing={{ xs: 2, md: 2 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <div className={Styles.ourteam}>
                <div className={Styles.ourteamcontainer}>
                  {Data.map((item, index) => (
                    <Grid item xs={4} sm={4} md={3} key={index}>
                      <img src={item.src} alt={item.alt} />
                    </Grid>
                  ))}
                </div>

                <div className={Styles.ourteamcontainer}>
                  {Data.map((item, index) => (
                    <Grid item xs={4} sm={4} md={3} key={index}>
                      <Zoom>
                        <h3>{item.title}</h3>
                        <h4>{item.role}</h4>
                      </Zoom>
                    </Grid>
                  ))}
                </div>
              </div>
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
}

const Data = [
  {
    title: "Khakaba Omukunda",
    src: "./profiles/Khakaba.jpg",
    role: "Team Leader",
  },
  {
    title: "Rinah Andayi",
    src: "./profiles/Rinah.jpg",
    role: "ICT Specialist",
  },
  {
    title: "Zedekiah Okal",
    src: "./profiles/Okal.jpg",
    role: "Finance and Accounting",
  },
  {
    title: "Easther Nechio",
    src: "./profiles/Easther.jpg",
    role: "Senior Reseacher",
  },
];
