import React from "react";
import Styles from "./styles.module.css";
import useTopSlider from "../hooks/useTopSlide";
import ScrollToTop from "../hooks/scrolltotop";


function ReserachCalls() {
  ScrollToTop()
  const {
    featuredImage = "/images/researchcalls.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);
  return (
    <div className={Styles.container}>
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
      <div className={Styles.subcontainer}>
        <h3>ReserachCalls</h3>
        <p>
          AGREC is excited to announce its Call for Papers, inviting scholars,
          researchers, and practitioners to contribute to our esteemed journal.
          Aligned with our commitment to advancing knowledge in Good Governance,
          Community Development, and related fields, this call seeks
          high-quality research papers and articles for peer review and
          publication.{" "}
        </p>
        <p>
          We welcome submissions that explore innovative perspectives,
          methodologies, and practical insights, fostering a diverse discourse
          on pressing global issues. By participating in AGREC's Call for
          Papers, contributors have the opportunity to share their expertise,
          engage with a broader audience, and contribute to the ongoing dialogue
          shaping positive change. Join us in building a collaborative platform
          for knowledge exchange and impactful research.
        </p>
      </div>
    </div>
  );
}

export default ReserachCalls;
