import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Styles from "./styles.module.css";
import { KeyboardDoubleArrowRight } from "@mui/icons-material";

export default function PersonalInformation({ next }) {
  const countries = require("./countries.json");
  const counties = require("./counties.json");

  const formik = useFormik({
    initialValues: {
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      sector: "",
      organisation: "",
      officenumber: "",
      personalnumber: "",
      country: "",
      county: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      organisation: Yup.string().required("Required"),
      sector: Yup.string().required("Required"),
      officenumber: Yup.string().required("Required"),
      personalnumber: Yup.string().required("Required"),
      county: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      next(values);
    },
  });
  return (
    <div className={Styles.container}>
      <form onSubmit={formik.handleSubmit}>
        <h3>----------------Personal Information----------------</h3>
        <hr />
        <div>
          <label htmlFor="title">*Title</label>
          <select
            id="title"
            name="title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title}
          >
            {titleData.map((data, index) => (
              <option key={index} value={data}>
                {data}
              </option>
            ))}
          </select>
          <div className={Styles.errors}>
            {formik.touched.title && formik.errors.title ? (
              <div>{formik.errors.title}</div>
            ) : null}
          </div>
        </div>
        <div>
          <label htmlFor="firstName">*First Name</label>
          <input
            id="firstName"
            name="firstName"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
          />
          <div className={Styles.errors}>
            {formik.touched.firstName && formik.errors.firstName ? (
              <div>{formik.errors.firstName}</div>
            ) : null}
          </div>
        </div>
        <div>
          <label htmlFor="lastName">*Last Name</label>
          <input
            id="lastName"
            name="lastName"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
          />
          <div className={Styles.errors}>
            {formik.touched.lastName && formik.errors.lastName ? (
              <div>{formik.errors.lastName}</div>
            ) : null}
          </div>
        </div>
        <div>
          <label htmlFor="email">*Email Address</label>
          <input
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          <div className={Styles.errors}>
            {formik.touched.email && formik.errors.email ? (
              <div>{formik.errors.email}</div>
            ) : null}
          </div>
        </div>
        <div>
          <label htmlFor="organisation">*Organisation</label>
          <input
            id="organisation"
            name="organisation"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.organisation}
          />
          <div className={Styles.errors}>
            {formik.touched.organisation && formik.errors.organisation ? (
              <div>{formik.errors.organisation}</div>
            ) : null}
          </div>
        </div>
        <div>
          <label htmlFor="sector">*Sector</label>
          <select
            id="sector"
            name="sector"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.sector}
          >
            {sectorData.map((data, index) => (
              <option key={index} value={data}>
                {data}
              </option>
            ))}
          </select>
          <div className={Styles.errors}>
            {formik.touched.sector && formik.errors.sector ? (
              <div>{formik.errors.sector}</div>
            ) : null}
          </div>
        </div>
        <div className={Styles.subcontainer}>
          <div>
            <label htmlFor="officenumber">*Office Number</label>
            <input
              id="officenumber"
              name="officenumber"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.officenumber}
            />
            <div className={Styles.errors}>
              {formik.touched.officenumber && formik.errors.officenumber ? (
                <div>{formik.errors.officenumber}</div>
              ) : null}
            </div>
          </div>
          <div>
            <label htmlFor="personalnumber">*Personal Number</label>
            <input
              id="personalnumber"
              name="personalnumber"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.personalnumber}
            />
            <div className={Styles.errors}>
              {formik.touched.personalnumber && formik.errors.personalnumber ? (
                <div>{formik.errors.personalnumber}</div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={Styles.subcontainer}>
          <div>
            <label htmlFor="county">*County</label>
            <select
              id="county"
              name="county"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.county}
            >
              {counties.map((county, index) => (
                <option key={index} value={county.name}>
                  {county.name} {"- 0"}
                  {county.countyno}
                </option>
              ))}
            </select>
            <div className={Styles.errors}>
              {formik.touched.county && formik.errors.county ? (
                <div>{formik.errors.county}</div>
              ) : null}
            </div>
          </div>
          <div>
            <label htmlFor="country">*Country</label>
            <select
              id="country"
              name="country"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              d
              value={formik.values.country}
            >
              {countries.map((country, index) => (
                <option key={index} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
            <div className={Styles.errors}>
              {formik.touched.country && formik.errors.country ? (
                <div>{formik.errors.country}</div>
              ) : null}
            </div>
          </div>
        </div>
        <button type="submit">
          <p>Next</p> <KeyboardDoubleArrowRight />
        </button>
      </form>
    </div>
  );
}

const titleData = [
  "Mr",
  "Ms",
  "Mrs",
  "Dr",
  "Prof",
  "Eng",
  "Sir",
  "Madam",
  "Miss",
  "Mx",
  "Rev",
  "Fr",
  "Sr",
];

const sectorData = [
  "Public Sector (Government)",
  "Private Sector (Business and Industry)",
  "Civil Society Organizations (CSOs)",
  "Faith-Based Organizations (FBOs)",
  "Non-Governmental Organizations (NGOs)",
  "Education and Academia",
  "Healthcare and Public Health",
  "Legal and Judicial Sector",
  "Media and Communications",
  "International Organizations and Development Agencies",
];
