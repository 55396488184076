const Resources = [
  {
    id: "0001",
    menu: "Press Releases",
    link: "media/press releases",
    section: "aboutus",
  },

  {
    id: "0002",
    menu: "Journals & Articles",
    link: "journal/journal papers",
    section: "aboutus",
  },

  {
    id: "0004",
    menu: "Research Calls",
    link: "journal/research calls",
    section: "aboutus",
  },
  {
    id: "0005",
    menu: "Conference 2024",
    link: "media/conference 2024",

    section: "aboutus",
  },
  {
    id: "0006",
    menu: "Gallery",
    link: "media/gallery",
    section: "aboutus",
  },
];

export default Resources;
