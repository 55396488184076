import { useState, useEffect } from 'react';

const useCurrentPageInfo = () => {
  const [pageInfo, setPageInfo] = useState({
    title: '',
    url: '',
  });

  useEffect(() => {
    const title = document.title;
    const url = window.location.href;

    setPageInfo({
      title,
      url,
    });
  }, []);

  return pageInfo;
};

export default useCurrentPageInfo;
