import React from "react";
import Styles from "./styles.module.css";
import { Slide } from "react-awesome-reveal";

export default function VisionMission() {
  return (
    <div className={Styles.ourmission}>
      <div className={Styles.ourmissioncolumns}>
        <h1>Vision</h1>
        <Slide>
          <p>
            Empowering nations through informed governance, where transparency,
            accountability, and citizen participation drive sustainable
            development
          </p>
        </Slide>
        <h1>Mission</h1>
        <Slide>
          <p>
            To equip leaders, organizations, and communities with tailored
            training and consultancy services, navigating the complexities of
            governance structures with integrity, efficiency, and effectiveness
          </p>
        </Slide>
      </div>
      <div className={Styles.ourmissioncolumns}>
        <img src="/images/visionmission.png" alt="AGREC-Vision Mission" />
      </div>
    </div>
  );
}
