import React from "react";
import Styles from "./styles.module.css";
import useTopSlider from "../hooks/useTopSlide";
import ScrollToTop from "../hooks/scrolltotop";

function StrategicPlan() {
  ScrollToTop()
  const {
    featuredImage = "/aboutus/strategicplan.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);

  const data1 = data.slice(0, 5);
  const data2 = data.slice(5);
  return (
    <div className={Styles.container}>
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
      <h1>
        AGREC's {new Date().getFullYear()}-{new Date().getFullYear() + 1}{" "}
        Strategic Plan
      </h1>
      <p>
        AGREC's 2024-2025 Strategic Plan is a visionary roadmap aimed at
        solidifying our position as a Regional leader in Governance, Democracy,
        Inclusion, and Development services. Focused on adaptability and
        innovation, Our plan centers on leveraging our wealth of experience and
        expertise to address evolving challenges in Governance Structures.
      </p>
      <h3>Key pillars include:</h3>
      <div className={Styles.highlight2}>
        {data1.map((item, index) => (
          <div key={index}>
            <span>{item.plan}</span> <p>{item.description}</p>
          </div>
        ))}
      </div>
      <div className={Styles.highlight2}>
        {data2.map((item, index) => (
          <div key={index}>
            <span>{item.plan}</span> <p>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StrategicPlan;

const data = [
  {
    plan: "Regional Expansion:",
    description:
      "Extending our reach to new regions,fostering partnerships, and tailoring solutions to diverse local needs.",
  },
  {
    plan: "Technological Integration::",
    description:
      "Harnessing cutting-edge technologies like Machine Learning, Data Science, Data Analytics,Artificial Intelligence, for enhanced research, communication, and project management, ensuring efficiency and effectiveness.",
  },
  {
    plan: "Capacity Building:",
    description:
      "Investing in our team's professional growth, cultivating a dynamic pool of experts equipped to meet the demands of a rapidly changing world",
  },
  {
    plan: "Sustainability Initiatives:",
    description:
      "Integrating sustainable practices into our projects, aligning with global efforts for environmental and social responsibility.",
  },
  {
    plan: "Strategic Alliances:",
    description:
      "Forging strategic partnerships with like-minded organizations to amplify our impact and broaden our collective influence.",
  },
  {
    plan: "Inclusive Innovation:",
    description:
      "embracing diversity and inclusivity in our approach, ensuring solutions are tailored to different cultural contexts.",
  },
  {
    plan: "Data-Driven Decision-Making:",
    description:
      "Utilizing robust data analytics for informed decision-making, enhancing the precision and efficacy of our consultancy services.",
  },
  {
    plan: "Client-Centric Approach:",
    description:
      "Strengthening our commitment to client satisfaction, ensuring a personalized and responsive experience.",
  },
];
