import React from "react";
import Slider from "./components/slider/Slider";
import ShowCase from "./components/showcase/showcase";
import OurWork from "./components/showcase/ourwork";
import VisionMission from "./components/showcase/visionmission";
import WhereWeWork from "./components/showcase/wherewework";
import OurTeam from "./components/showcase/ourteam";

function Home() {
  return (
    <div>
      <Slider /> <ShowCase />
      <WhereWeWork />
      <VisionMission />
      <OurWork />
      <OurTeam />
    </div>
  );
}

export default Home;
