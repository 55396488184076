import React, { useEffect, useState } from "react";
import Styles from "./styles.module.css";
import BoardData from "./boarddata";
import { Facebook } from "react-content-loader";
import ScrollToTop from "../hooks/scrolltotop";

function BoardMembersEditorialTeam() {
  ScrollToTop();
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(true);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  useEffect(() => {
    async function delayFunc() {
      await delay(2000);
      setTeamData(BoardData);
      setLoading(false);
    }
    delayFunc();
  }, []);
  // const MyLoader = () => (
  //   <ContentLoader>
  //     <rect x="0" y="0" rx="5" ry="5" width="50" height="70" />
  //     <rect x="80" y="17" rx="4" ry="4" width="700" height="13" />
  //     <rect x="80" y="40" rx="3" ry="3" width="250" height="10" />
  //   </ContentLoader>
  // );

  const MyFacebookLoader = () => <Facebook />;

  if (loading)
    return (
      <div>
        <h1>Our Team</h1>

        <div className={Styles.highlight}>
          <MyFacebookLoader />
        </div>
        <div className={Styles.highlight}>
          <MyFacebookLoader />;
        </div>
      </div>
    );
  
  return (
    <div >
      <div className={Styles.subcontainer}>
        <h1>Our Board Members</h1>

        {teamData.map((member, index) => (
          <div key={index}>
            <TeamMembers
              src={member.src}
              alt={member.alt}
              username={member.username}
              profile={member.profile}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default BoardMembersEditorialTeam;

const TeamMembers = ({ src, alt, username, profile }) => (
  <>
    <div className={Styles.highlight}>
      <>
        <div className={Styles.imageContainer}>
          <img src={src} alt={alt} />
        </div>
        <div className={Styles.profileText}>
          <h3>{username}</h3>
          <p>{profile}</p>
        </div>
      </>
    </div>
  </>
);
