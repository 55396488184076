import React from "react";
import Styles from "../styles.module.css";

function Objective() {
  return (
    <div>
      {" "}
      <div className={Styles.highlight}>
        <h3>MAIN OBJECTIVE</h3>
        <p>
          The overall objective of the conference will be to enhance good
          governance for national development and prosperity.
        </p>
        <h3>Specific Objectives</h3>
        {data3.map((item, index) => (
          <ol key={index}>
            <li>{item.title}</li>
          </ol>
        ))}
        <h3>Expected Outputs</h3>
        {data4.map((item, index) => (
          <ol key={index}>
            <li>{item.title}</li>
          </ol>
        ))}
      </div>
    </div>
  );
}

export default Objective;

const data3 = [
  { title: "Sharing and exchanging experiences, lessons and ideas." },
  {
    title:
      "Building a knowledge base/repository of innovations and best practices.",
  },
  {
    title:
      "Establishing a knowledge network of practitioners, researchers, academics.",
  },
  { title: "Contribute towards good governance practices." },
  {
    title:
      "Elevate good governance including gender responsive governance and its practice in an evolving democracy",
  },
  {
    title:
      "Launch and institutionalize (AGREC as) a knowledge platform on governance for development.",
  },
];

const data4 = [
  { title: "Action areas and resolutions" },
  { title: "Conference Report " },
  { title: "AGREC Journal Publication (addition of (1) and (2)" },
  { title: "Sustained (ethics and) good governance consciousness mindset" },
];
