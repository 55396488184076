import React, { useEffect, useState } from "react";
import { Facebook } from "react-content-loader";
import { Journals } from "../journal/journalData";

function useTopSlider(delayTime = 1000, topSliderimage) {
  const [featuredImage, setFeaturedImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [journal, setJournal] = useState([]);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const PageFeaturedImage = ({ src, alt }) => <img src={src} alt={alt} />;
  
  useEffect(() => {
    async function delayImage() {
      await delay(delayTime);
      setLoading(false);
      setFeaturedImage(topSliderimage);
      setJournal(Journals);
    }
    delayImage();
  }, [delayTime,topSliderimage]);
  const MyFacebookLoader = () => <Facebook />;

  return {
    featuredImage,
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
    journal
  };
}
export default useTopSlider;
