import React from "react";
import Styles from "./contacts.module.css";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { Formik, Field, Form } from "formik";
import Stack from "@mui/material/Stack";
import LinearProgress from "@mui/material/LinearProgress";


const ContactForm = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Formik
        initialValues={{
          contactname: "",
          contactnumber: "",
          email: "",
          subject: "",
          message: "",
        }}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          emailjs
            .sendForm(
              "service_iq4h4yq",
              "template_2rab8tm",
              "#myForm",
              "Ij0mUmYw4UItn-v1E"
            )
            .then(
              function (response) {
                console.log("SUCCESS!", response.status, response.text);
                LinearColor();
                navigate("/contact/thankyou");
              },
              function (error) {
                console.log("FAILED...", error);
              }
            );

          // alert(JSON.stringify(values, null, 2));
        }}
      >
        <Form className={Styles.container} id="myForm">
          <label htmlFor="contactname">Name</label>
          <Field id="contactname" name="contactname" placeholder="Name" />

          <label htmlFor="contactnumber">Number</label>
          <Field id="contactnumber" name="contactnumber" placeholder="Number" />

          <label htmlFor="email">Email Address</label>
          <Field
            id="email"
            name="email"
            placeholder="Email Address"
            type="email"
          />
          <label htmlFor="subject">Subject</label>
          <Field id="subject" name="subject" placeholder="Subject" />
          <label htmlFor="message">Message</label>
          <Field
            as="textarea"
            id="message"
            name="message"
            placeholder="Message"
          />
          <button className={Styles.button} type="submit">
            Submit
          </button>
        </Form>
      </Formik>
    </div>
  );
};

export default ContactForm;

function LinearColor() {
  return (
    <Stack sx={{ width: "100%", color: "grey.500" }} spacing={2}>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack>
  );
}
