import React from "react";
import Styles from "./styles.module.css";
import useTopSlider from "../hooks/useTopSlide";
import ScrollToTop from "../hooks/scrolltotop";

function OurValues() {
  ScrollToTop();
  const {
    featuredImage = "/aboutus/ourvalues.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);

  const data1 = data.slice(0, 5);
  const data2 = data.slice(5);
  return (
    <div className={Styles.container}>
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
      <div className={Styles.subcontainer}>
        <h1>Our Values</h1>
        <p>
          AGREC is anchored in a set of unwavering values that define our work
          ethics. Integrity forms the cornerstone of our operations, ensuring
          honesty, transparency, and ethical conduct in all endeavors.
          Innovation drives our approach, fostering creative solutions to
          intricate challenges.
        </p>
        <div className={Styles.highlight2}>
          {data1.map((item, index) => (
            <div key={index}>
              <span>{item.value}</span> <p>{item.description}</p>
            </div>
          ))}
        </div>
        <div className={Styles.highlight2}>
          {data2.map((item, index) => (
            <div key={index}>
              <span>{item.value}</span> <p>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurValues;

const data = [
  {
    value: "Integrity:",
    description:
      "Upholding the highest standards of honesty,transparency, and ethical conduct in all our interactions.",
  },
  {
    value: "Innovation:",
    description:
      "Embracing a culture of creativity and adaptability to provide cutting-edge solutions to complex challenges.",
  },
  {
    value: "Collaboration:",
    description:
      "Recognizing the strength of diverse perspectives and fostering teamwork to achieve collective goals.",
  },
  {
    value: "Client Satisfaction:",
    description:
      "Committed to delivering excellence and ensuring the utmost satisfaction of our clients in every project.",
  },
  {
    value: "Respect:",
    description:
      "Valuing and respecting individuals, communities, and stakeholders, promoting inclusivity in all engagements.",
  },
  {
    value: "Sustainability:",
    description:
      "Guided by a commitment to sustainable development, seeking to create positive, long-lasting impacts.",
  },
  {
    value: "Professionalism:",
    description:
      "Conducting ourselves with the highest level of professionalism, competence, and expertise in our consultancy services.",
  },
  {
    value: "Accountability:",
    description:
      "Taking responsibility for our actions and outcomes, acknowledging both successes and areas for improvement.",
  },
  {
    value: "Social Responsibility:",
    description:
      "Recognizing our role in society, we strive to contribute positively to the well-being of communities and the environment.",
  },
  {
    value: "Continuous Improvement:",
    description:
      "Embracing a culture of learning and development, continuously evolving to stay at the forefront of our field.",
  },
];
