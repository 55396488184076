const participantsData = [
  { participants: "Participants" },
  { participants: " Students Participants  " },
  { participants: "  Local Exhibitors  " },
  {
    participants: "International Exhibitors ",
  },
];

const EarlyRegistration = [
  { participants: "Kes 30,000 (USD 260)" },
  { participants: " KES 15000 (USD 130)  " },
  { participants: " KES, 50,000 for three days   " },
  {
    participants: "USD 1000 for three days  ",
  },
];

const LateRegistration = [
  { participants: "KES 35000 (USD 310)" },
  { participants: " KES 20,000 (USD 175)" },
  { participants: " KES, 50,000 for three days   " },
  {
    participants: "USD 1000 for three days  ",
  },
];

const ConferenceActivity = [
  { activity: "Submission of abstract" },
  { activity: " Acceptance of abstract Notification " },
  { activity: " Submission of Full papers  " },
  {
    activity: "Acceptance of Corrected Full Papers  ",
  },
  {
    activity: "Submission of corrected Full Papers ",
  },
  {
    activity: "Submission of Post conference Revised Full papers ",
  },
  {
    activity: "Submission of Camera-Rady Proofs ",
  },
  {
    activity: "Publication of proceedings as edited Book ",
  },
];
const ConferenceDates = [
  { date: "20th March 2024" },
  { date: "3rd April 2024  " },
  {
    date: "30th June 2024",
  },
  { date: "28th July 2024" },
  { date: " 31st August 2024" },
  { date: " 16th November 2024  " },
  {
    date: "12th January 2025 ",
  },
  {
    date: "22nd March 2025 ",
  },
];

export {
  ConferenceActivity,
  ConferenceDates,
  LateRegistration,
  EarlyRegistration,
  participantsData,
};
