import React, { useState } from "react";
import PersonalInformation from "./personalinformation";
import ConferencePreferences from "./conferencepreferences";
import DateAccomodation from "./dateaccomodation";
import { useNavigate } from "react-router-dom";

const RegistrationForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  const next = (values) => {
    setFormData({ ...formData, ...values });
    setStep(step + 1);
  };

  const prev = () => {
    setStep(step - 1);
  };

  const submit = async (values) => {
    setFormData({ ...formData, ...values });

    const finalFormData = { ...formData, ...values };

    try {
      const response = await fetch("https://agrec.org/send-email/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ formData: finalFormData }),
      });

      if (response.ok) {
        navigate("/thank you");
      } else {
        alert("Failed to send email. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error submitting form. Please try again.");
    }
  };

  switch (step) {
    case 1:
      return <PersonalInformation next={next} />;
    case 2:
      return <ConferencePreferences next={next} prev={prev} />;
    case 3:
      return <DateAccomodation submit={submit} prev={prev} />;
    default:
      return <PersonalInformation next={next} />;;
  }
};

export default RegistrationForm;
