const FooterLinks = [
  {
    id: "0001",
    menu: "About Us",
    link: "/about us/our story",
    section: "aboutus",
  },

  {
    id: "0002",
    menu: "Privacy & Cookies ",
    link: "Privacy & Cookies ",
    section: "aboutus",
  },
  {
    id: "0003",
    menu: "Terms & Conditions",
    link: "Terms & Conditions",
    section: "aboutus",
  },
  {
    id: "0004",
    menu: "Feedback",
    link: "Feedback",
    section: "aboutus",
  },
  {
    id: "0005",
    menu: "Conference 2024",
    link: "media/conference 2024",

    section: "aboutus",
  },
  {
    id: "0006",
    menu: "Contact Us",
    link: "contact",
    section: "aboutus",
  },
];

export default FooterLinks;
