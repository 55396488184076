import React from "react";
import Styles from "./styles.module.css";
import PoliticalPartiesTable from "./tables/politicalpartiestable";
import useTopSlider from "../hooks/useTopSlide";
import ScrollToTop from "../hooks/scrolltotop";

function PoliticalPartiesLiason() {
  ScrollToTop()
  const {
    featuredImage = "/images/slider4.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);

  return (
    <div className={Styles.container}>
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
      <h1>
        Democracy, Political Parties & Political Parties Liaison Committees{" "}
      </h1>
      <h3>Democracy</h3>
      <p>
        The active and meaningful participation of citizens in public affairs is
        the distinguishing feature of democratic societies, which are judged by
        the extent to which governments open up to citizen involvement in public
        affairs and the space they give for citizens to hold the government
        accountable. It is because of this that citizens in Kenya and Africa in
        general continue to demand increased space for participation. (Open
        Society Foundation)
      </p>

      <p>
        A democracy cannot exist without the presence of a political party. From
        a broader perspective, a political party is a means via which the people
        can speak to the government and have a say in the governance of any
        country. The presence of the political party is a healthy situation for
        the nation. It gives people a choice to make a more evolved and
        effective decision.
      </p>

      <p>
        The Political Parties Act, 2011 Sec (38) establishes the Political
        Parties Liaison Committee at the National and County Level. The
        principal function of the Political Parties Liaison Committee is to
        provide a platform for dialogue between the Registrar, Electoral
        Commission and political parties. Office of the Registrar has sustained
        the structured dialogue between political parties and the Electoral
        Commission on issues relating to the electoral process in Kenya through
        various workshops.
      </p>
      <h3>AGREC's Intervention Solutions</h3>
      <p>
        AGREC collaborates with election institutions, IEBC and ORPP, to build
        the capacity of political parties and Political parties Liaison
        committees, civil society organizations and local and international
        election observers to ensure a democratic society. We do this through
        offering Training and Consultancy. In the following areas among others.
      </p>

      <div className={Styles.maintable}>
        <PoliticalPartiesTable />
      </div>
    </div>
  );
}

export default PoliticalPartiesLiason;
