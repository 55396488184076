export const TrainingData = [
  { training: "Program based budgeting" },
  { training: " Development of sector plans " },
  { training: "  Public participation framework " },
  {
    training: "Public expenditure management ",
  },
  { training: "Public Finance Management " },
  { training: "Intergovernmental Relations " },
  { training: "Induction of County MCAs, CEC, and Directors." },
  { training: "Training of the County Assembly House committee " },
  { training: "Policy making" },
  { training: "Performance Contracting." },
  { training: "Resource mobilization and proposal writing" },
  { training: "Effective Citizen Engagement in planning and Budget process" },
  { training: "Training of Board of Management on leadership and Governance" },
  { training: "Coaching and Mentorship" },
  { training: "Emotional Intelligence" },
  { training: "Communication skills and Public speaking" },
  { training: "Change Management" },
  { training: "Climate Change" },
];

export const Consultancies = [
  {
    consultancy: "Development of County Integrated Plan Development Plan(CIDP)",
  },
  {
    consultancy: " Development Annual Development Plan (ADP), CFSP",
  },
  {
    consultancy:
      "Development of Institutional Policies, such as HR policies, Communication policies, social media policies, strategic plans",
  },
  {
    consultancy:
      "Finance policies, volunteer policies, gender violence policies, Child protection policies, Procurement Policies etc ",
  },
  { consultancy: "Development of organisation reporting framework" },
  {
    consultancy: "Mapping of stakeholders ",
  },
  { consultancy: "Policy and sector plan development " },
  { consultancy: "Performance Contracting" },
  { consultancy: "Development of County Monitoring and Evaluation Framework" },
  { consultancy: "Development of policies and laws " },
  { consultancy: "Development of memorandums " },
  {
    consultancy:
      "Establishment and operationalization of sector working groups    ",
  },
];
