import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaWhatsapp,
  FaTiktok,
} from "react-icons/fa";
import useSocialShare from "./useSocialShare";

const SocialShare = ({ image, description }) => {
  const shareUrls = useSocialShare({ image, description });

  return (
    <div className="social-share">
      <a href={shareUrls.facebook} target="_blank" rel="noopener noreferrer">
        <FaFacebookF />
      </a>
      <a href={shareUrls.twitter} target="_blank" rel="noopener noreferrer">
        <FaTwitter />
      </a>
      <a href={shareUrls.linkedin} target="_blank" rel="noopener noreferrer">
        <FaLinkedinIn />
      </a>
      <a href={shareUrls.whatsapp} target="_blank" rel="noopener noreferrer">
        <FaWhatsapp />
      </a>
      <a href={shareUrls.tiktok} target="_blank" rel="noopener noreferrer">
        <FaTiktok />
      </a>
      <a
        href={`https://instagram.com`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaInstagram />
      </a>
    </div>
  );
};

export default SocialShare;
