const Journals = [
  {
    title: "Funds and Economic Empowerment.",
    image: "/journal/cover1.jpg",
    authors:
      "Kennedy Khakaba Omukunda (MSc, PhD Candidate), Prof. Maurice Sakwa (Ph.D)",
    link: "Funds and Economic Empowerment.pdf",
  },
  {
    title:
      "Rethinking Recentralization of Devolved Functions in Kenya - Nairobi City County Experience",
    image: "/journal/cover2.jpg",
    authors:
      "Kennedy Khakaba Omukunda (MSc, PhD Candidate) & Leah Indiya Khakaba",
    link: "Rethinking Recentralization of Devolved Functions in Kenya - Nairobi City County Experience.pdf",
  },
  {
    title: "Saving and Economic Empowerment",
    image: "/journal/cover3.jpg",
    authors:
      "Kennedy Khakaba Omukunda (MSc, PhD Candidate), Prof. Maurice Sakwa (Ph.D)",
    link: "Saving and Economic Empowerment.pdf",
  },
  {
    title: "Social Protection and Economic Empowerment",
    image: "/journal/cover4.jpg",
    authors:
      "Kennedy Khakaba Omukunda (MSc, PhD Candidate), Prof. Maurice Sakwa (Ph.D)",
    link: "Social Protection and Economic Empowerment.pdf",
  },
  {
    title: "The Role of CBO and Economic Empowerment",
    image: "/journal/cover5.jpg",
    authors:
      "Kennedy Khakaba Omukunda (MSc, PhD Candidate), Prof. Maurice Sakwa (Ph.D)",
    link: "The Role of CBO and Economic Empowerment.pdf",
  },
];

export { Journals };
