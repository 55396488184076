import React from "react";
import Styles from "../styles.module.css";

function Exhibitions() {
  return (
    <div>
      <h3>EXHIBITION BOOTHS BOOKING</h3>
      <h4>Approach</h4>
      <p>
        Part of the conference events will include exhibition by traders,
        innovators and product promoters. Space will be available for the
        exhibition activities.
      </p>

      <p>The exhibition is aimed at achieving the following objectives:</p>
      <div className={Styles.highlight}>
        {data.map((item, index) => (
          <li key={index}>{item.objective}</li>
        ))}
      </div>
    </div>
  );
}

export default Exhibitions;

const data = [
  { objective: "To showcase best practices and innovations." },
  {
    objective:
      "To promote knowledge sharing and learning amongst various practitioners, County Governments, and the public.",
  },
  { objective: "To show case Kenya’s innovative export products" },
];
