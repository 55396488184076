import React, { useEffect } from "react";

export default function ScrollToTop() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      scrollToTop();
    }, 20);

    return () => clearTimeout(timerId);
  }, []);
}




