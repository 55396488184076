import React from "react";

function Justification() {
  return (
    <div>
      {" "}
      <h3>PURPOSE AND JUSTIFICATION </h3>
      <p>
        The Inaugural International Multi-Sectoral Conference on Governance and
        Development in Kenya 2024, recognizes the gains and challenges Kenya and
        world is undergoing in ensuring a balance between governance and
        development. The conference will provide a platform for stakeholders to
        generate information around key sectors in governance and development.
        The Conference is an important event as it provides a platform through
        which all stakeholders in the governance and development space generate
        knowledge both from local and international perspectives that are useful
        in shaping various Kenya government programs and development strategies.
        It will additionally provide a forum where government can learn from the
        global emerging best practices in implementing policies that enhance the
        quality-of-service provision and attainment of sustainable development
        results besides providing networking opportunities.
      </p>
    </div>
  );
}

export default Justification;
