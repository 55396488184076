import React from "react";
import Styles from "../styles.module.css";

function Reviewers() {
  return (
    <div>
      <h4>Reviewers of the Concept </h4>
      <p>
        AGREC would like to acknowledge the following Persons, State Departments
        and Ministries, for their invaluable support in preparing this concept
        note for Conference:
      </p>
      <div className={Styles.highlight}>
        {data.map((item, index) => (
          <li key={index}>{item.member}</li>
        ))}
      </div>
    </div>
  );
}

export default Reviewers;
const data = [
  { member: "Dennis Kabaara" },
  { member: "Prof Peter Okoth" },
  { member: "Douglas Ouma (PhD)" },
  { member: "Mary Murimi" },
  { member: "Rosemary Mbaluka" },
  { member: "Joseph Kosure (PhD)" },
  { member: "Saskia Brechenmacher(PhD)" },
  { member: "Prof Guliano Bosi" },
  { member: "Teddy Chenya(Editor and Designer)" },
  { member: "The National Treasury and Economic Planning," },
  { member: "Ministry of Information & Communication& The Digital Economy, " },
  { member: "Ministry of Environment & Forestry" },
  {
    member:
      "Ministry of Co-operatives and Micro, Small and Medium Enterprises (MSMEs) Development",
  },
  { member: "Ministry of Investments, Trade & Industry, Ministry of Health" },
  { member: "Ministry of Agriculture and Livestock Development" },
  { member: "State Department of Devolution)" },
  { member: "Independent Electoral and Boundaries Commission(IEBC)" },
  { member: "Registrar of Political Parties(ORPP)" },
  { member: "Commission of Revenue Allocation(CRA)" },
  { member: "Intergovernmental Technical Relations Committee(IGRTC)" },
  { member: "AHADI Public Expenditure Management(PEM) Mentors Group" },
  { member: "Community United For Rural Development " },
  { member: "Kakamega County Civil Society Organization members" },
  { member: "Commisioner Data Protection" },
];
