import React from "react";
import Styles from "../styles.module.css";

function Participants() {
  return (
    <div>
      <h3>LIST OF PARTICIPANTS</h3>
      <p>
        To have robust engagements, the conference will seek to invite the
        following groups:
      </p>
      <div className={Styles.highlight}>
        {data.map((item, index) => (
          <li key={index}>{item.group}</li>
        ))}
      </div>
    </div>
  );
}

export default Participants;

const data = [
  {
    group:
      "Representatives of county and national government institutions including Parliament, Judiciary",
  },
  { group: "Civil Society Organizations" },
  { group: "Media" },
  { group: "Religious institutions" },
  { group: "Sub-national governments from Africa and other regions" },
  { group: "Development partners" },
  { group: "Academia" },
  { group: "Private sector practitioners" },
];


