export const TrainingData = [
  { training: "Organization development system and strengthening (ODSS)" },
  { training: "Organization Capacity Assessments (OCA)" },
  { training: "Monitoring and evaluation" },
  {
    training:
      "Reporting, and development of success stories and Human-Interest stories",
  },
  { training: "Resource mobilization and proposal writing" },
  { training: "Social accountability" },
  { training: "Grants management" },
  { training: "Civic engagement and access to information" },
  { training: "Outcome harvesting training" },
  { training: "Effective Citizen Engagement in planning and Budget process" },
  { training: "Training of Board of Management on leadership and Governance" },
  { training: "Coaching and Mentorship" },
  { training: "Emotional Intelligence" },
  { training: "Social accountability" },
  { training: "Communication skills and Public speaking" },
  { training: "Change Management" },
  { training: "Climate Change" },
];

export const Consultancies = [
  { consultancy: "Third-Party Monitoring" },
  {
    consultancy:
      "Program Evaluation, Baseline, Midterm and End term evaluation",
  },
  { consultancy: "Outcome Harvesting" },
  { consultancy: "Development of MERLA Framework" },
  { consultancy: "Mentorship of CSOs in Program implementation" },
  {
    consultancy:
      "Development of Institutional Policies, such as HR policies, Communication policies, social media policies, strategic plans, Finance policies, volunteer policies, gender violence policies, Child protection policies, Procurement Policies",
  },
  { consultancy: "Development of organisation reporting framework" },
  { consultancy: "Mapping of stakeholders" },
  { consultancy: "Development of an operational framework" },
  { consultancy: "Development of IEC materials" },
];
