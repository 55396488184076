import React from "react";
import Styles from "./styles.module.css";
import useTopSlider from "../hooks/useTopSlide";
import ScrollToTop from "../hooks/scrolltotop";

function SpecialTrainings() {
  ScrollToTop()
  const {
    featuredImage = "/images/slider6.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);

  return (
    <div className={Styles.container}>
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
      <h1>Special Services/Training</h1>

      <div className={Styles.highlight}>
        <h3>IEC Materials</h3>
        <p>
          Development and design of informational educational materials (IEC
          Materials), including translation of documents to braille, and Kenya
          sign language, and supply of assistive devices.
        </p>
      </div>
      <div className={Styles.highlight}>
        <h3>Specialized training </h3>
        <p>
          Child welfare and protection, parenting care, household economic
          strengthening, SILC, financial literacy, and OVC Care
          Training.Financial Capabilities, My Health My Choice, Health Choices
          for Better Future and SHUGA.
        </p>
      </div>
      <div className={Styles.highlight}>
        <h3>ICT Training: </h3>
        <p>
          Maintenance of software and hardware, social media, communication
          e-mail etiquette and cyber security, supply of computers, laptops and
          computer accessories.
        </p>
      </div>
    </div>
  );
}

export default SpecialTrainings;
