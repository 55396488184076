import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Styles from "./styles.module.css";

function Theme1() {
  const [themes, setThemes] = useState([]);
  const ThemesData = require("../conference/themesdata.json");

  useEffect(() => {
    const fetchData = () => {
      try {
        setThemes(ThemesData);
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };
    fetchData();
  }, [ThemesData]);

  return (
    <div className={Styles.themesContainer}>
      {themes.map((theme, index) => (
        <div className={Styles.productCard} key={index}>
          <div className={Styles.productInfo}>
            <h5>
              <span>[{theme.theme}:]</span>
              {theme.title}
            </h5>
            <h3>Sub-Themes:</h3>
            {theme.points.map((point, index1) => (
              <p key={index1}>{point.id},</p>
            ))}
          </div>
          <div className={Styles.addcart}>
            <div className={Styles.addcartSection}>
              <Link to={theme.link}>
                <button className={Styles.button}>Read More</button>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Theme1;
