import React from "react";
import useTopSlider from "../../hooks/useTopSlide";
import Styles from "../styles.module.css";
import ScrollToTop from "../../hooks/scrolltotop";

export default function ThankYou() {
  ScrollToTop();
  const {
    featuredImage = "/images/slider6.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);

  return (
    <div className={Styles.container}>
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
      <h3>Thank You</h3>
      <div style={{ padding: "1rem" }}>
        <p>
          Thank you for registering for the Inaugural International
          Multi-Sectoral Conference on Governance and Development in Kenya 2024!
          We appreciate your interest in participation.{" "}
        </p>
        <p>
          Your registration details have been received, and we're excited to
          welcome you to the event.{" "}
        </p>
        <p>
          Our team will review your information, and we'll revert back to you
          shortly with a confirmation and any additional details you may need.
          If you have any immediate questions or concerns, feel free to reach
          out to our support team. We look forward to hosting you at the
          conference!
        </p>
      </div>
      <h4>AGREC Team</h4>
    </div>
  );
}
