import React from "react";
import Styles from "./styles.module.css";
import useTopSlider from "../hooks/useTopSlide";
import ContactForm from "./contactform";
import ScrollToTop from "../hooks/scrolltotop";

function Contact() {
  ScrollToTop()
  const {
    featuredImage = "/images/contactus.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);

  return (
    <div className={Styles.container}>
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
      <h1>Contacts</h1>
      <div className={Styles.columnA}>
        <div className={Styles.highlight}>
          <h1>Get in touch</h1>
          <p>
            Our mission is to provide you with the best customized{" "}
            <span>Training solutions</span> and{" "}
            <span>Professional Consultancy Services</span> on Public Governance,
            Beauracracy, Democracy, Political Parties Management, Civil Society
            Organisations, Research and Communications, Monitoring and
            Evaluations, Capacity Development, and Project Management
          </p>
          <h4>Email</h4>
          <p>info@agrec.org or support@agrec.org</p>
          <h4>Tel/Mobile Contacts</h4>
          <p>+254 0115 026 637</p>
          <p>+254-100-371-646</p>
          <h4>Address</h4>
          <p>Eagle House 4th Floor, Suite 5 </p>
          <p>Kimathi Street, Nairobi CBD</p>
          <p>Kenya</p>
        </div>




        <div className={Styles.highlight}>
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default Contact;
