import React from "react";
import { Field } from "formik";
import Styles from "./styles.module.css";

export default function TravelInformation({
  handleChange,
  handleBlur,
  handleTravel,
  handleTouched,
  handleErrors,
}) {
  return (
    <div className={Styles.subcontainer2}>
      <div>
        <label htmlFor="travelInfo">*Travel Information</label>
        <select
          id="travelInfo"
          name="travelInfo"
          onChange={handleChange}
          onBlur={handleBlur}
          value={handleTravel}
        >
          {travelData.map((data, index) => (
            <option key={index} value={data}>
              {data}
            </option>
          ))}
        </select>
        <div>
          {handleTouched && handleErrors ? (
            <div className={Styles.errors}>{handleErrors}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
const travelData = [
  "Cab/Taxi",
  "Company Van",
  "Personal Means",
  "Transport Required",
];
