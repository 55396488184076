import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Styles from "./social.module.css";

const SocialMedia = () => {
  const [social, setSocial] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      try {
        setSocial(socials);
      } catch (error) {
        console.error("Error fetching social:", error);
      }
    };
    fetchData();
  }, []);

  console.log("Social Media", social);

  return (
    <div className={Styles.themesContainer}>
      {social.map((item, index) => (
        <div className={Styles.productCard} key={index}>
          <Link to={item.link} target="_blank">
            <img src={item.social} alt="AGREC" />
          </Link>
        </div>
      ))}
    </div>
  );
};

export default SocialMedia;

const socials = [
  {
    link: "https://www.facebook.com/agrecconsultants",
    social: "/socials/facebook.png",
  },
  {
    link: "https://www.linkedin.com/company/99986520/admin/feed/posts/ ",
    social: "/socials/linkedin.png",
  },

  {
    link: "https://twitter.com/AHADICONSU43399",
    social: "/socials/Xlogo.png",
  },
];
