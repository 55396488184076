import React from "react";
import Styles from "./styles.module.css";
import Introduction from "./conference/introduction";
import Background from "./conference/background";
import Justification from "./conference/justification";
import Objective from "./conference/objective";
import ConferenceFormat from "./conference/conferenceformart";
import Participants from "./conference/participants";
import Exhibitions from "./conference/exhibitions";
import Reviewers from "./conference/Reviewers";
import useTopSlider from "../hooks/useTopSlide";
import ScrollToTop from "../hooks/scrolltotop";

function ConferenceDetail() {
  ScrollToTop()
  const {
    featuredImage = "/images/conferencedetail.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);

  return (
    <div className={Styles.container}>
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
      <Introduction />
      <Background />
      <Justification />
      <Objective />
      <ConferenceFormat />
      <Participants />
      <Exhibitions />
      <Reviewers />
    </div>
  );
}

export default ConferenceDetail;
