import React from "react";
import Styles from "../styles.module.css";
import ConferenceThemes from "./conferencethemes";

function ConferenceFormat() {
  return (
    <div>
      <h3>PROPOSED CONFERENCE FORMAT</h3>
      <h4>1.0 Pre-Conference Activities: </h4>
      <div className={Styles.highlight2}>
        <p>a{")"}. Registration of participants</p>
        <p>
          b{")"}. The participants will visit and make a walk in the Karura
          forest on 3rd September 2024. This will help the delegate appreciate
          the national efforts and need for conserving environment as part of
          the bigger agenda on climate change governance and environmental
          management.
        </p>
      </div>
      <h4>1.1 Conference </h4>
      <ConferenceThemes />
    </div>
  );
}

export default ConferenceFormat;
