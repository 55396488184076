import React from "react";
import Styles from "./styles.module.css";


const Accommodation = ({
  handleChange,
  handleBlur,
  handleinterestedInAccommodation,
  handleTouchedinterestedInAccommodation,
  handleErrorsinterestedInAccommodation,
  handleBookingDate,
  handleTouchedBookingDate,
  handleErrorsBookingDate,
  handleCheckOutDate,
  handleTouchedCheckOutDate,
  handleErrorsCheckOutDate,
}) => {
  return (
    <div >
      <label htmlFor="interestedInAccommodation">
        Interested in Accommodation
      </label>
      <select
        id="interestedInAccommodation"
        name="interestedInAccommodation"
        onChange={handleChange}
        onBlur={handleBlur}
        value={handleinterestedInAccommodation}
      >
        <option value="" label="Select option" />
        <option value="Yes" label="Yes" />
        <option value="No" label="No" />
      </select>
      {handleTouchedinterestedInAccommodation &&
      handleErrorsinterestedInAccommodation ? (
        <div className={Styles.errors}>{handleErrorsinterestedInAccommodation}</div>
      ) : null}

      {handleinterestedInAccommodation === "Yes" && (
        <>
          <label htmlFor="bookingDate">Booking Date</label>
          <input
            id="bookingDate"
            name="bookingDate"
            type="date"
            onChange={handleChange}
            onBlur={handleBlur}
            value={handleBookingDate}
          />
          {handleTouchedBookingDate && handleErrorsBookingDate ? (
            <div className={Styles.errors}>{handleErrorsBookingDate}</div>
          ) : null}

          <label htmlFor="checkoutDate">Checkout Date</label>
          <input
            id="checkoutDate"
            name="checkoutDate"
            type="date"
            onChange={handleChange}
            onBlur={handleBlur}
            value={handleCheckOutDate}
          />
          {handleTouchedCheckOutDate && handleErrorsCheckOutDate ? (
            <div className={Styles.errors}>{handleErrorsCheckOutDate}</div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Accommodation;
