import React from "react";
import Styles from "./styles.module.css";
import useTopSlider from "../hooks/useTopSlide";
import ScrollToTop from "../hooks/scrolltotop";

function Methodologies() {
  ScrollToTop()
  const {
    featuredImage = "/images/methodologies.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);

  return (
    <div className={Styles.container}>
       {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
      <h3>Methodologies</h3>
      <p>
        AGREC strategically selects and combines these methodologies based on
        the nature of the research, ensuring a holistic and nuanced approach to
        address the complexities of diverse projects.
      </p>
      <div className={Styles.highlight}>
        <h3>Quantitative Surveys: </h3>
        <p>
          Employing structured questionnaires and statistical analysis to
          quantify and measure specific variables, providing numerical data for
          rigorous analysis.
        </p>
      </div>
      <div className={Styles.highlight}>
        <h3>Qualitative Research: </h3>
        <p>
          Conducting in-depth interviews, focus group discussions, and content
          analysis to gain insights into subjective experiences, opinions, and
          social phenomena.
        </p>
      </div>
      <div className={Styles.highlight}>
        <h3>Case Studies: </h3>
        <p>
          In-depth examination of specific instances or cases to extract
          detailed information, context, and insights, allowing for a nuanced
          understanding of complex issues.
        </p>
      </div>
      <div className={Styles.highlight}>
        <h3>Mixed-Methods Approach: </h3>
        <p>
          Integrating both quantitative and qualitative methods to obtain a
          comprehensive view of the research topic, combining statistical data
          with rich qualitative narratives.
        </p>
      </div>
      <div className={Styles.highlight}>
        <h3>Surveys using ODK/KOBO/CS: </h3>
        <p>
          Utilizing digital data collection tools like ODK (Open Data Kit),
          KOBO, and CS (CommCare) for efficient and accurate gathering of
          information in various settings.
        </p>
      </div>
    </div>
  );
}

export default Methodologies;
