const PublicFinanceData = [
  {
    id: "1",
    title: "100 Public Finance Management, taxation and public debt management",
    points: [
      {
        point:
          "Best practice in designing PFM reforms in developing countries and contemporary issues in Public Financial Management (PFM) Reforms.",
        id: "101",
      },
      {
        point:
          "Promotion of fiscal discipline, transparency, accountability and risk management and strengthening PFM Institution",
        id: "102",
      },
      {
        point:
          "Strengthening Public Financial Management Reforms in the State-Owned Enterprises (SoEs)",
        id: "103",
      },
      {
        point:
          "Reforms to strengthen efficiency in revenue mobilization, strengthen Tax Compliance and Administration in Kenya",
        id: "104",
      },
      {
        point:
          "Governance and taxation in devolved governance system, issues and opportunities.",
        id: "105",
      },
      {
        point:
          "Management of Public Debt in a turbulent economic environment of high interest rate and exchange rate depreciation",
        id: "106",
      },
      {
        point:
          "Establishment of guidelines to assist policymakers in considering reforms to strengthen the quality of public debt management and reduce the country’s vulnerability to domestic and international financial shocks",
        id: "107",
      },

      {
        point: "Strengthening public debt risk management in Kenya",
        id: "108",
      },
    ],
  },
];
const DevolutionData = [
  {
    id: "2",
    title: "200 Devolution, Intergovernmental Relations and Service Delivery",
    points: [
      {
        point:
          "Performance and Management of concurrent functions in devolved governance system,.",
        id: "201",
      },
      {
        point:
          "Governmental challenges and solutions in costing of devolved functions.",
        id: "202",
      },
      {
        point: "Sustainable financing of decentralized governance ",
        id: "203",
      },
      {
        point:
          "Intergovernmental Relations review of the current Status in Kenya.",
        id: "204",
      },
      {
        point: "The link between PFM and decentralization reforms.",
        id: "205",
      },
      {
        point:
          "Own source revenue collections, administration, forecasting models and enhancement strategies ",
        id: "206",
      },
      {
        point: "Health Financing in decentralized system ",
        id: "207",
      },
      {
        point: "County Assemblies, public accountability and Service delivery.",
        id: "208",
      },
      {
        point:
          "Insights on how to achieve greater transparency and accountability in Public Debt management",
        id: "209",
      },
      {
        point:
          "Unpacking framework and practices of combating child labor in Kenya devolved governance system ",
        id: "210",
      },
      {
        point:
          "Insights on how to achieve greater transparency and accountability in Public Debt management",
        id: "211",
      },
    ],
  },
];
const DemocraticGovernanceData = [
  {
    id: "3",
    title: "300 Best Practice in Democratic Governance",
    points: [
      {
        point:
          "Governance and Democracy (public participation; Civic Engagement in digital era).",
        id: "301",
      },
      {
        point:
          "Governance and Democracy (Electoral Security, Peace and Conflict Mitigation)",
        id: "302",
      },
      {
        point:
          "Governance and Democracy (Electoral legal framework and Dispute Resolution)",
        id: "303",
      },
      {
        point:
          "Governance and Democracy (Disinformation, Misinformation and Propaganda in Electoral Process and information ecosystem)",
        id: "304",
      },
      {
        point:
          "Affirmative Action and Inclusion of Women, Youth and Persons with Disabilities",
        id: "305",
      },
      {
        point: "Technology in the electoral process. ",
        id: "306",
      },
      {
        point:
          "Nexus between Political Parties, IEBC and Nomination Processes. ",
        id: "307",
      },
      {
        point:
          "Nexus between Political Parties, Independent Candidates, and agent Management ",
        id: "308",
      },
      {
        point:
          "Unpacking framework and practices of combating child labor in Kenya devolved governance system ",
        id: "309",
      },
      {
        point: "Coalitions and Consensus in emerging democracies",
        id: "310",
      },
      {
        point: "Youth and Democracy in Africa",
        id: "311",
      },
      {
        point:
          "International Election Observation and enhancing Democracy in Africa",
        id: "312",
      },
      {
        point: "Local Election Observation and enhancing Democracy in Kenya",
        id: "313",
      },
      {
        point: "Devolution and New Politics of Development in Kenya ",
        id: "314",
      },
      {
        point:
          " Governance, women with disabilities in politics and Leadership in Kenya ",
        id: "315",
      },
      {
        point:
          " Role of Signs Disability awareness and training enhancing inclusion ",
        id: "316",
      },
      {
        point:
          " Devolution, public participation processes and disability Inclusion ",
        id: "317",
      },
      {
        point:
          " Role and impact of representation of person with disabilities in county assemblies on enhancing disability inclusion ",
        id: "318",
      },{
        "point": "Governance, women with disabilities in politics and Leadership in Kenya",
        "id": "319"
      },
      {
        "point": "Role of Signs Disability awareness and training enhancing inclusion",
        "id": "320"
      },
      {
        "point": "Devolution, public participation processes and disability Inclusion",
        "id": "321"
      },
      {
        "point": "Role and impact of representation of person with disabilities in county assemblies on enhancing disability inclusion",
        "id": "322"
      }
    ],
  },
];

const DataProtectionData = [
  {
    id: "4",
    title:
      "400 Emerging issues and best practice in Data protection, digital governance and access to information",
    points: [
      {
        point:
          "Emerging issues in data protection and cyber security in the digital world",
        id: "401",
      },
      {
        point:
          "Digital service delivery, Bridging the digital Divide and Digital youth budge",
        id: "402",
      },
      {
        point: "Techno Nationalism and Artificial Intelligence (AI)",
        id: "403",
      },
      {
        point:
          "Mechanisms for enhancing compliance with proactive disclosure and records management standards.",
        id: "404",
      },
      {
        point:
          "Mechanisms for enhancing public interests’ disclosures in Kenya",
        id: "405",
      },
      {
        point:
          "Examining the role of private bodies in access to Information implementation",
        id: "406",
      },
      {
        point:
          "Applying alternative dispute resolution mechanisms in right to information disputes",
        id: "407",
      },
      {
        point:
          "Balancing competing interests; the place of limitations in information disclosures",
        id: "408",
      },
    ],
  },
];
const PolicyMakingData = [
  {
    id: "5",
    title: "500 Policy Making, Execution and Service Delivery",
    points: [
      {
        point:
          "Multi-sectoral Responsibility to Societal Security and Human Rights",
        id: "501",
      },
      {
        point:
          "Governance and Socio-Economic Development (Education, Health, Water)",
        id: "502",
      },
      {
        point: "Governance and Water Management ",
        id: "503",
      },
      {
        point:
          "Governance, Climate Change and Food Security in the devolved and developing country",
        id: "504",
      },
      {
        point: "Governance and Water Management in devolved governance system ",
        id: "505",
      },
      {
        point:
          "Pastoralism and climate change from devolved and developing countries perspective",
        id: "506",
      },
      {
        point:
          "Governance and Housing, context, issues, challenges and opportunities, best practice and recommendations",
        id: "507",
      },
      {
        point:
          "Innovative health policies and practices of delivering affordable healthcare in devolved and developing countries",
        id: "508",
      },
      {
        point:
          "Governance and Economic Empowerment of persons with Disabilities. Context, issues, opportunities and recommendation",
        id: "509",
      },
      {
        point: "Pastoralism and climate change from gender perspective ",
        id: "510",
      },
      {
        point:
          "Unpacking the climate change justice in the context of community projects ",
        id: "511",
      },
      {
        point:
          "Governance, Financial inclusion and economic growth from context of  devolved and developing countries ",
        id: "512",
      },
      {
        point: "Governance and Water Management ",
        id: "513",
      },
      {
        point: "Governance and Agricultural Development",
        id: "514",
      },
      {
        point: "Governance and productivity of a co-operative society",
        id: "515",
      },
      {
        point: "Governance and co-operative society development",
        id: "516",
      },
      {
        point:
          "Cooperative governance and social performance of cooperative societies",
        id: "517",
      },
      {
        point:
          "Unpacking the climate change market credit in the context of community projects ",
        id: "518",
      },
      {
        point:
          "Strategies, local and Practice in mainstreaming climate change, in government, community and civil society organization.",
        id: "519",
      },
      {
        point:
          "Emerging best practice, challenges, opportunities localization of development and foreign aid in the developing countries.",
        id: "520",
      },
      {
        point: "Governance, Financial inclusion and economic growth ",
        id: "521",
      },
      {
        point:
          "Strategies, local and Practice in mainstreaming climate change, in government, community and civil society organization.",
        id: "522",
      },

      {
        point: "Unpacking Kenya sign Language.",
        id: "523",
      },
      {
        point:
          "Strategies, practice, local and international best practice in combating child labor in post Covid-19 Period.",
        id: "524",
      },
      {
        point: "Unpacking Governance of Biotechnology and Food Production ",
        id: "525",
      },
      {
        point:
          "Unpacking Governance of Nuclear Energy and Development from developing countries perspective  ",
        id: "526",
      },
      {
        point: "Governance and Aquaculture in Kenya",
        id:"527"
      }
    ],
  },
];

const EthicalData = [
  {
    id: "6",
    title: "600 Ethical Accountable Governance in Public Service",
    points: [
      {
        point: "Multi-sectoral approach and Corruption.",
        id: "601",
      },
      {
        point: "Public accountability and Civil Society organization",
        id: "602",
      },
      {
        point:
          "Social Accountability and Service Delivery in devolved governance system.",
        id: "603",
      },
      {
        point:
          "Social Accountability, Youth and Service Delivery in devolved governance system",
        id: "604",
      },
      {
        point:
          "Citizen Participation and Public Expenditure Management in devolved governance system",
        id: "605",
      },
      {
        point: "Social Accountability and Service Delivery.",
        id: "606",
      },
      {
        point:
          "Accountability and county government in Kenya.(Change corruption to accountability)",
        id: "607",
      },
    ],
  },
];

export {
  PublicFinanceData,
  DevolutionData,
  DemocraticGovernanceData,
  DataProtectionData,
  PolicyMakingData,
  EthicalData,
};
