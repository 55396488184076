const BoardData = [
  {
    username: "Kennedy Khakaba Omukunda",
    src: "/profiles/Khakaba.jpg",
    alt: "Khakaba Omukunda",
    profile:
      "Team Leader at AGREC, he has more than 10 years experience, conceptualizing, implementing and managing, Governance, Democracy, inclusion, and development program both level of government, Private sector and civil society organizations. Previously, Kennedy has worked for, International Democratic Institute (NDI), USAID AHADI, Sauti Kuu Foundation, AMURT Africa, Hope Worldwide Kenya. He has consulted for Westminster Foundation for Democracy in Kenya. He has conceptualized and implemented program funded by USAID, FCDO, UN, WorldBank, ILO/IPEC, among others. He is an ongoing PhD in Development Studies, he holders Msc in Development Studies(JKUAT), B.A in Sociology and Political Science(UON), Diploma in Project Management(KIM), Diploma Medical Science(TUK) and Certificate in Monitoring and Evaluation. Kennedy is passionate about generating knowledge to empower governance systems not only Africa but the World.",
  },

  {
    username: "Easther N. Nechio",
    src: "/profiles/Easther.jpg",
    alt: "Easther N. Nechio",
    profile:
      "Nechio is a researcher with specialization in climate change and data analysis. Previously, she has worked Government of Kenya Department of Meteorology and JKIA  in Department of Meteorology as Weather Forecaster. She holds Bsc in Meteorology and various certificates in research.",
  },
  {
    username: "John Ochieng",
    src: "/profiles/John.jpg",
    alt: "John Ochieng",
    profile:
      "John Ochieng is a public policy and a governance specialist with other specific strengths and experience in legislative affairs; democracy and governance; participatory governance & social accountability; project cycle management; policy advocacy, evaluations & assessments; and evidence in decision making. He is an experienced budget analyst, a good outcome harvester, a trainer in participatory development processes, program design and management, program evaluation, and policy reviews in areas of public finance, education, health, agriculture, gender, and public participation." +
      "John is a holder of a Master of Arts Degree in Project Planning and Management and a Bachelor of Arts Degree in Political Science and Sociology, both from the University of Nairobi, and an Executive Certificate on Problem Driven Iterative Adaptation from Harvard University. ",
  },
  {
    username: "Teddy Chenya",
    src: "/profiles/Teddy.jpg",
    alt: "Teddy Chenya",
    profile:
      "I am a Strategic professional who believes in the power of communications to galvanize local development and spur change. With over 15 years of communication and leadership experience in East Africa, my work includes 10 years with programs funded by the United States Agency for International Development (USAID), and The Foreign, Commonwealth and Development Office (FCDO). With a Bachelor's Degree in Mass Communications and skills ranging from feature writing, editing, layout, photography, video production and web content development. My passion has always been to build a better world.",
  },
  {
    username: "Sellah Atieno Opiyo",
    src: "/profiles/Sellah.jpg",
    alt: "Sellah Atieno Opiyo",
    profile:
      "A Governance and political specialist, currently serving as the Deputy Secretary General of Political Parties Liaison Committee (PPLC). PPLC is a platform that brings together registered political parties, Registrar of Political Parties (ORPP), Independent Electoral and Boundaries Commission (IEBC) and development Partners to discuss political issues in Kenya. She is also serving as National Youth League Chairperson. She joins AGREC as the Board of Director in charge matters political affairs and elections. She brings vast experience working with political parties, electoral bodies, grassroot mobilization and her experience in electoral inclusion working with women, youth and Persons with Disability as she believes",
  },
];

export default BoardData;
