import React from "react";
import Styles from "./styles.module.css";
import Logo from "../images/logo.png";
import LogoText from "../images/logotext.png";
import UpcomingEvent from "../images/upcomingevent.png";
import EventDetail from "../images/eventdates.png";
import { Link } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";

function MainMenu() {
  return (
    <div className={Styles.rightSide}>
      <div className={Styles.rightSideA}>
        <div className={Styles.rightSideAAA}>
          <Link to="/home">
            <img src={Logo} alt="AGREC" />
          </Link>
        </div>
        <div className={Styles.rightSideAAB}>
          <Link to="/home">
            <img src={LogoText} alt="AGREC" />
          </Link>
        </div>
      </div>
      <div className={Styles.rightSideB}>
        <Zoom>
          <div className={Styles.rightSideABA}>
            <Link to="./media/conference 2024">
              <img src={UpcomingEvent} alt="AGREC" />
            </Link>
          </div>
          <div className={Styles.rightSideABB}>
            <Link to="./media/conference 2024">
              <img src={EventDetail} alt="AGREC" />
            </Link>
          </div>
        </Zoom>
      </div>
    </div>
  );
}

export default MainMenu;
