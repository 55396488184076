import React from "react";
import Styles from "./styles.module.css";
import useTopSlider from "../hooks/useTopSlide";
import ScrollToTop from "../hooks/scrolltotop";

function Projects() {
  ScrollToTop()
  const {
    featuredImage = "/images/projects.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);

  return (
    <div className={Styles.container}>
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
      <h1>Accomplished Projects</h1>
      <p>
        Immerse yourself in a tapestry of insights, from groundbreaking research
        findings to collaborative initiatives shaping global change. Stay
        informed about our engagements with Civil Society organizations,
        Governments, and diverse sectors.
      </p>
      <p>
        AGREC's Press Releases offer a glimpse into our commitment to
        transparency, innovation, and the relentless pursuit of excellence in
        consultancy services. Join us on this journey, where each press release
        is a testament to our dedication to driving positive transformations
        across communities and sectors.
      </p>
      <div className={Styles.highlight}>
        <h3>Coming up..</h3>
      </div>
    </div>
  );
}

export default Projects;
