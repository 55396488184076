import React from "react";
import { Link } from "react-router-dom";
import Resources from "./resources";

function ColumnC() {
  return (
    <div>
      <h3>Resources</h3>
      <ul>
        {Resources.map((menu) => (
          <div key={menu.id}>
            <li>
              <Link to={menu.link}>{menu.menu}</Link>
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
}

export default ColumnC;
