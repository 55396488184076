// GalleryData.js

const galleryData = [
  {
    category: "Conference",
    id: 1,
    title: "Conference",
    imageUrl: process.env.PUBLIC_URL + "/Gallery/conference.jpg",
    description: "Description for Landscape 1",
  },
  {
    category: "Training",
    id: 2,
    title: "Trainings",
    imageUrl: process.env.PUBLIC_URL + "/Gallery/training.jpg",
    description: "Description for Landscape 2",
  },
  {
    category: "Workshop",
    id: 3,
    title: "Projects",
    imageUrl: process.env.PUBLIC_URL + "/Gallery/workshop2.jpg",
    description: "Description for Landscape 2",
  },
  {
    category: "Workshop",
    id: 4,
    title: "WorkShop",
    imageUrl: process.env.PUBLIC_URL + "/Gallery/workshop.jpg",
    description: "Description for Landscape 2",
  },
  {
    category: "Workshop",
    id: 5,
    title: "WorkShop",
    imageUrl: process.env.PUBLIC_URL + "/Gallery/projects.jpg",
    description: "Description for Landscape 2",
  },

];

export default galleryData;
