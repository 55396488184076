import React from "react";


function Background() {
  return (
    <div >
      {" "}
      <h3>BACKGROUND TO THE CONFERENCE </h3>
      <p>
        The Inaugural International Multi-Sectoral Conference on Governance for
        Development in Kenya 2024, would be annual conference that provides
        local, regional and international governance and development
        practitioners, researchers, academicians and graduate students with the
        opportunity to share experiences, lessons and transformative ideas on
        the links between governance, human development and national prosperity.
        It aims at establishing a dynamic network of practitioners, researchers
        and academicians by providing periodic mechanism for consultation,
        collaboration and partnership. The theme of the conference will be
        “Consolidating proven knowledge for effective governance, development,
        growth and prosperity in Kenya”.
      </p>
      <p>
        In developing this conference concept note, AGREC consulted widely with
        government ministries , government departments , civil society
        organizations and the private sector. Using a demand-driven approach,
        conference papers and proceedings will facilitate the sharing and
        exchange of experiences, lessons and ideas among stakeholders while
        providing a platform to develop innovative, yet practical, solutions and
        best practices in governance for development in Kenya.
      </p>
      <p>
        It is further expected that the conference will generate important
        knowledge products, which will be shared with the Government of Kenya
        and institution to help strengthen governance systems through better
        policy, laws and systems. To ensure the conference knowledge products
        are implementation, AGREC will continue holding multi-stakeholders’
        breakfast virtual/Physical roundtable meetings to discuss the
        resolutions and papers presented in the conference to see progress
        towards products adoption and use. Finally, all accepted papers from
        contributing stakeholders shall be published in the AGREC International
        Journal of Governance for Development that is currently being
        established.{" "}
      </p>
      <p>
        The conference will contribute towards the following outcomes: Prudent
        use Public Finance, through Public Finance Management, Taxation and Debt
        Management. Secondly, improved service delivery through strengthened
        Devolution and Intergovernmental Relations. Third, improved democratic
        governance. Improved management of data protection and digital
        governance. Forth, Improved policy execution and service delivery in
        government. Improved accountability.
      </p>
    </div>
  );
}

export default Background;
