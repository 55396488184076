import React from "react";
import Styles from "./styles.module.css";
import useTopSlider from "../hooks/useTopSlide";
import ScrollToTop from "../hooks/scrolltotop";
import { Fade } from "react-awesome-reveal";

function OurStory() {
  ScrollToTop();
  const {
    featuredImage = "/aboutus/ourstory.jpg",
    loading,
    PageFeaturedImage,
    MyFacebookLoader,
  } = useTopSlider(2000);

  return (
    <div className={Styles.container}>
      {loading ? (
        <MyFacebookLoader />
      ) : (
        <PageFeaturedImage src={featuredImage} alt={"AGREC"} />
      )}
      <div className={Styles.subcontainer}>
        <div className={Styles.subcontainer}>
          <h3>Our Story</h3>
          <Fade delay={200}>
            <p>
              In a world grappling with complex socio-political challenges,{" "}
              <span>AGREC</span>, a cutting-edge Consultancy & Research Firm,
              emerges as a beacon of change. As a powerhouse specializing in
              aiding Government Institutions (Nation & County Governments),
              Civil Society organizations, Private Sectors, Political Parties,
              and None Govermental Organisations , AGREC strives to redefine the
              landscape of Governance and Development. The storyline unfolds as
              AGREC takes on a transformative agenda, collaborating with a
              partners and stakeholders to implement Good Governance practices.
            </p>
          </Fade>
          <h3>Our Approach</h3>
          <p>
            Led by a dynamic team of Professional Experts, Researchers,
            Consultants, and Academicians, AGREC traverses the intricate web of
            Public Governance, Beauracracy, Democracy, Political Parties
            Management, Civil Society Organisations, Research and
            Communications, Monitoring and Evaluations, Capacity Development,
            and Project Management by offering pragmatic beskope{" "}
            <span>Training solutions</span> and {"  "}
            <span>Professional Consultancy services </span> while balancing
            political interests and the genuine needs of the people.
          </p>
          <h3>Our Journey</h3>
          <Fade delay={200}>
            <p>
              As the narrative unfolds, AGREC faces challenges that test our
              limits of expertise and commitment. Over the years, AGREC has
              become catalyst for positive change, fostering sustainable
              development and leaving an indelible mark on the Institutional
              Governance and Management. AGREC's journey is a testament to the
              impact of dedicated professionals striving for a world where good
              governance and community development are not just ideals but
              tangible realities.
            </p>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default OurStory;
