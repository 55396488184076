import React from "react";
import { Outlet } from "react-router-dom";
import Styles from "./styles.module.css";

import Footer from "../footer/";
import NavBar from "../navbar/navbar";
import ScrollToTop  from "../hooks/scrolltotop";

function Layout() {
  ScrollToTop();

  return (
    <div>
      <NavBar />
      <div className={Styles.container}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
